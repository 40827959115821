import {colors} from "react_ct/theme";

export const INVALID_STAGES = [
    "duplicate",
    "rescanned",
    "archive",
    "archived",
    "deleted",
    "invalid_upload",
    "false_scan",
    "true_invalid_upload",
];

export const STAGE_COLORS: Record<string, string> = {
    Processing: colors.deepWalkBlue,
    Completed: colors.green,
    Rescan: colors.red,
    Uploaded: colors.yellow,
};

export const STAGE_MAP = new Proxy<Record<string, string>>(
    {
        rasterization: "Uploaded",
        rescan_check: "Uploaded",
        testStage: "Uploaded",
        uploadSuccess: "Uploaded",
        uploadInit: "Uploaded",
        rescan: "Rescan",
        passed: "Completed",
    },
    {
        // if a stage doesn't exist in the map, return "Processing" (after checking if the stage is valid)
        get: (target, name: string) => (name in target ? target[name] : "Processing"),
    },
);

export const STAGE_OPTIONS = ["Processing", "Completed", "Rescan", "Uploaded"];
