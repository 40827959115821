import React from "react";
import styled from "styled-components";
import {useTheme} from "@mui/material/styles";
import {Box, Divider} from "@mui/material";
import {colors} from "react_ct/theme";

export const FOOTER_HEIGHT = 40;

const FooterContainer = styled.footer<{secondarycolor: string}>`
    width: 100%;
    color: ${props => props.secondarycolor};
    padding: 1.5rem;
    box-sizing: border-box;
    text-align: center;
    height: ${FOOTER_HEIGHT}px;
    font-size: 0.8rem;
    z-index: 6;
    position: relative;
`;

export const Footer: React.FC = () => {
    const theme = useTheme();
    return (
        <FooterContainer secondarycolor={theme.palette.secondary.main}>
            <Divider />
            <Box component="p" sx={{color: colors.darkGray}}>
                &copy; {new Date().getFullYear()} DeepWalk Research
            </Box>
        </FooterContainer>
    );
};
