import type mapboxgl from "mapbox-gl";
import {type AnnotationOption} from "./MapComponents";

export interface FilterLayer {
    id: string;
    layers: string[] | number[];
}

export const filterByAnnotation = (
    annotationControl: boolean,
    annotationOption: AnnotationOption,
): mapboxgl.Expression => {
    if (annotationControl) {
        if (annotationOption === "circles") {
            return ["==", "$type", "Point"];
        } else if (annotationOption === "polylines") {
            return ["==", "$type", "LineString"];
        }
    }
    // don't do anything special if the annotation control is not enabled
    return ["in", "$type", "Point", "LineString", "Polygon"];
};

export const filterByFilterLayer = (filterLayers: FilterLayer[]): mapboxgl.Expression[] =>
    filterLayers.map(filterLayer => ["in", filterLayer.id, ...filterLayer.layers]);

export const createSelectionFilterArray = (
    annotationControl: boolean,
    annotationOption: AnnotationOption,
    dataId: string | string[],
    filterLayers: FilterLayer[] | undefined,
    selectedFeatures: GeoJSON.Feature[] | GeoJSON.Feature | undefined,
    cluster = false,
): mapboxgl.Expression => {
    // Define the filter by annotation condition
    const annotationFilter = filterByAnnotation(annotationControl, annotationOption);

    // Create the selection filter array based on the annotation option
    if (Array.isArray(dataId)) {
        // For array dataId, use "any" to match any of the IDs in dataId
        return [
            "all",
            [
                "any",
                ...dataId.map(id => {
                    if (Array.isArray(selectedFeatures)) {
                        return [
                            "in",
                            dataId,
                            ...(selectedFeatures?.map(selectedFeature => selectedFeature.properties?.id) ?? ""),
                        ];
                    } else {
                        return ["==", id, selectedFeatures?.properties?.id ?? ""];
                    }
                }),
            ],
            ...(filterLayers ? filterByFilterLayer(filterLayers) : []),
            annotationFilter,
            cluster ? ["has", "point_count"] : ["!has", "point_count"],
        ];
    } else {
        // For single dataId, use "all" to match all conditions
        if (Array.isArray(selectedFeatures)) {
            const selectedIds =
                selectedFeatures?.map(selectedFeature => selectedFeature?.properties?.[dataId] ?? "") ?? [];

            return [
                "all",
                ["in", dataId, ...selectedIds],
                ...(filterLayers ? filterByFilterLayer(filterLayers) : []),
                annotationFilter,
            ];
        } else {
            const filterArray: mapboxgl.Expression = [
                "all",
                ["==", dataId, selectedFeatures?.properties?.[dataId] ?? ""],
                ...(filterLayers ? filterByFilterLayer(filterLayers) : []),
                annotationFilter,
            ];
            return filterArray;
        }
    }
};

export const createFilterArray = (
    annotationControl: boolean,
    annotationOption: AnnotationOption,
    filterLayers: FilterLayer[] | undefined,
    cluster = false,
): mapboxgl.Expression => {
    const annotationFilter = filterByAnnotation(annotationControl, annotationOption);
    return [
        "all",
        ...(filterLayers ? filterByFilterLayer(filterLayers) : []),
        annotationFilter,
        ["!has", "point_count"],
    ];
};

export const createClusterArray = (
    annotationControl: boolean,
    annotationOption: AnnotationOption,
    filterLayers: FilterLayer[] | undefined,
) => {
    const annotationFilter = filterByAnnotation(annotationControl, annotationOption);
    return [...(filterLayers ? filterByFilterLayer(filterLayers) : []), annotationFilter];
};
