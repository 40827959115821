import Tooltip, {type TooltipProps, tooltipClasses} from "@mui/material/Tooltip";
import styled from "@mui/material/styles/styled";
import React from "react";

export const HtmlTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.background.paper,
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        boxShadow: theme.shadows[6],
        padding: 8,
    },
}));
