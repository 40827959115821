import {type AnnotationOption} from "./MapComponents";
import {type PaintStyle, type ShapeType, type MapStyle, type LayerShapeType} from "./constants";

export const sourceKeyToLayerShape = (key: ShapeType): LayerShapeType => {
    switch (key) {
        case "Point":
            return "circle";
        case "LineString":
            return "line";
        case "Polygon":
            return "fill";
        case "Symbol":
            return "symbol";
    }
};

export const createCirclePaintOptions = (
    paintStyle: PaintStyle,
    currentStyle: MapStyle,
    hasDrawFeatures: boolean,
): mapboxgl.CirclePaint => {
    const selected = paintStyle === "selected";
    return {
        "circle-radius": selected ? 10 : 5,
        "circle-color": ["get", "color"],
        "circle-opacity": selected ? 0.5 : hasDrawFeatures ? 0.4 : 1,
        "circle-stroke-color": selected ? "transparent" : currentStyle === "satellite" ? "white" : "black",
        "circle-stroke-width": 1,
    };
};

export const createLinePaintOptions = (
    paintStyle: PaintStyle,
    currentStyle: MapStyle,
    hasDrawFeatures: boolean,
): mapboxgl.LinePaint => {
    const selected = paintStyle === "selected";
    const stroke = paintStyle === "stroke";
    return {
        "line-width": selected ? 20 : stroke ? 7 : 5,
        "line-color": stroke ? (currentStyle === "satellite" ? "white" : "black") : ["get", "color"],
        "line-opacity": selected || hasDrawFeatures ? 0.5 : hasDrawFeatures ? 0.4 : 1,
    };
};

export const createPolygonPaintOptions = (paintStyle: PaintStyle): mapboxgl.FillPaint => {
    const selected = paintStyle === "selected";
    return {
        "fill-color": ["get", "color"],
        "fill-opacity": selected ? 0.5 : 0.35,
        "fill-outline-color": ["get", "color"],
    };
};

export const returnPaintOptions = (
    key: ShapeType,
    paintStyle: PaintStyle,
    currentStyle: MapStyle,
    hasDrawFeatures: boolean,
): mapboxgl.CirclePaint | mapboxgl.LinePaint | mapboxgl.FillPaint | undefined => {
    switch (key) {
        case "Point":
            return createCirclePaintOptions(paintStyle, currentStyle, hasDrawFeatures);
        case "LineString":
            return createLinePaintOptions(paintStyle, currentStyle, hasDrawFeatures);
        case "Polygon":
            return createPolygonPaintOptions(paintStyle);
        case "Symbol":
            return undefined;
    }
};

export const setLayerVisibility = (
    layerShapeType: ShapeType,
    annotationControl: boolean,
    annotationOption: AnnotationOption,
): "visible" | "none" => {
    if (!annotationControl) return "visible";
    else {
        if (annotationOption === "circles") {
            return layerShapeType === "Point" ? "visible" : "none";
        } else if (annotationOption === "polylines") {
            return layerShapeType === "LineString" ? "visible" : "none";
        } else {
            return "visible";
        }
    }
};

export function createLayerProps(
    layerId: string,
    layerType: LayerShapeType,
    layerShapeType: ShapeType,
    layerPaintStyle: PaintStyle,
    symbolId: mapboxgl.Expression,
    annotationControl: boolean,
    annotationOption: AnnotationOption,
    selectionFilterArray: mapboxgl.Expression,
    filterArray: mapboxgl.Expression,
    currentStyle: MapStyle,
    hasDrawFeatures: boolean,
): mapboxgl.CircleLayer | mapboxgl.LineLayer | mapboxgl.FillLayer | mapboxgl.SymbolLayer {
    switch (layerType) {
        case "symbol":
            return {
                id: layerId,
                type: layerType as "symbol",
                layout: {
                    visibility: setLayerVisibility(layerShapeType, annotationControl, annotationOption),
                    "icon-image": symbolId,
                    "icon-allow-overlap": true,
                },
                filter:
                    layerPaintStyle === "selected"
                        ? selectionFilterArray
                        : layerPaintStyle === "cluster"
                          ? ["has", "point_count"]
                          : filterArray,
            };
        case "circle":
            return {
                id: layerId,
                type: layerType as "circle",
                layout: {
                    visibility: setLayerVisibility(layerShapeType, annotationControl, annotationOption),
                },
                paint: returnPaintOptions(
                    layerShapeType,
                    layerPaintStyle,
                    currentStyle,
                    hasDrawFeatures,
                ) as mapboxgl.CirclePaint,
                filter:
                    layerPaintStyle === "selected"
                        ? selectionFilterArray
                        : layerPaintStyle === "cluster"
                          ? ["has", "point_count"]
                          : filterArray,
            };
        case "fill":
            return {
                id: layerId,
                type: layerType as "fill",
                layout: {
                    visibility: setLayerVisibility(layerShapeType, annotationControl, annotationOption),
                },
                paint: returnPaintOptions(
                    layerShapeType,
                    layerPaintStyle,
                    currentStyle,
                    hasDrawFeatures,
                ) as mapboxgl.FillPaint,
                filter: layerPaintStyle === "selected" ? selectionFilterArray : filterArray,
            };
        case "line":
            return {
                id: layerId,
                type: layerType as "line",
                layout: {
                    visibility: setLayerVisibility(layerShapeType, annotationControl, annotationOption),
                },
                paint: returnPaintOptions(
                    layerShapeType,
                    layerPaintStyle,
                    currentStyle,
                    hasDrawFeatures,
                ) as mapboxgl.LinePaint,
                filter: layerPaintStyle === "selected" ? selectionFilterArray : filterArray,
            };
    }
}
