import React, {useContext, createContext, useState, useEffect} from "react";
import {webUserSchema, type WebUser} from "react_ct/types";
import {useLocalStorage} from "hooks/useLocalStorage";
import {getCookie, loginRequest, removeAllCookies, saveCookie, spoofedLoginRequest} from "react_ct/requests";

const useAuth = (): AuthContextType => {
    return useContext(AuthContext);
};

interface AuthContextType {
    user: WebUser | null;
    userLoaded: boolean;
    signin?: (user: string, password: string, callback: (role: string) => void) => void;
    spoofedSignin?: (userId: number, callback: VoidFunction) => void;
    signout?: (callback: VoidFunction) => void;
    loginError?: string;
    setLoginError?: React.Dispatch<React.SetStateAction<string | undefined>>;
    checkIsAdmin?: () => boolean;
}

export const AuthContext = createContext<AuthContextType>({
    user: null,
    userLoaded: false,
    signin: undefined,
    spoofedSignin: undefined,
    signout: undefined,
    loginError: undefined,
    setLoginError: undefined,
    checkIsAdmin: undefined,
});

interface AuthProviderProps {
    children: JSX.Element;
}

const AuthProvider: React.FC<AuthProviderProps> = (props: AuthProviderProps) => {
    const {children} = props;
    const {getItem, setItem} = useLocalStorage();

    const [user, setUser] = useState<WebUser | null>(null);
    const [userLoaded, setUserLoaded] = useState(false);
    const [loginError, setLoginError] = useState<string>();

    useEffect(() => {
        findUser();
    }, []);

    const findUser = (): void => {
        const userItem = getItem("user");
        if (!userItem) {
            setUser(null);
            setUserLoaded(true);
            return;
        }
        // jwt is in the cookie, not local storage
        const jwt = getCookie("accessToken");
        const parsedUser = webUserSchema.safeParse({...JSON.parse(userItem), jwt});
        if (!parsedUser.success) {
            console.warn("Error parsing stored user", parsedUser.error);
            setUser(null);
            setUserLoaded(true);
            removeUser();
            return;
        }
        setUser({...parsedUser.data});
        setUserLoaded(true);
    };

    const storeUser = (user: WebUser | null): void => {
        if (!user) {
            throw new Error("User is not defined");
        }
        const {jwt, ...rest} = user;
        setUser(user);
        saveCookie("accessToken", jwt);
        if (user.role === "ADMIN") {
            saveCookie("adminAccessToken", jwt);
        }
        setItem("user", JSON.stringify(rest));
        setUserLoaded(true);
    };

    const removeUser = (): void => {
        setUser(null);
        setItem("user", "");
    };

    const signin = async (username: string, password: string, callback: (role: string) => void): Promise<void> => {
        try {
            const parsedResponse = await loginRequest(username, password);
            const {accessToken: jwt, ...rest} = parsedResponse;
            storeUser({jwt, spoofed: false, ...rest});
            callback(parsedResponse.role);
        } catch (e) {
            const error: Error = e as Error;
            setLoginError(error.message);
        }
    };

    const spoofedSignin = async (userId: number, callback: VoidFunction): Promise<void> => {
        try {
            const parsedResponse = await spoofedLoginRequest(userId);
            const {accessToken: jwt, ...rest} = parsedResponse;
            storeUser({jwt, spoofed: true, ...rest});
            callback();
        } catch (e) {
            const error: Error = e as Error;
            setLoginError(error.message);
        }
    };

    const signout = async (callback: VoidFunction): Promise<void> => {
        // clear cache and cookies
        await clearCache();
        removeAllCookies();
        removeUser();
        callback();
    };

    const checkIsAdmin = (): boolean => {
        const adminJwt = getCookie("adminAccessToken");
        const isAdmin =
            user?.role === "ADMIN" || user?.role === "admin" || user?.spoofed === true || Boolean(user?.admin);
        return isAdmin && adminJwt !== undefined;
    };

    const value = {
        user,
        userLoaded,
        signin,
        signout,
        spoofedSignin,
        loginError,
        setLoginError,
        storeUser,
        checkIsAdmin,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

async function clearCache(cacheName = "S3_v1"): Promise<void> {
    // Open the cache
    const cache = await caches.open(cacheName);

    // Get all the entries' keys (requests) in the cache
    const requests = await cache.keys();

    // Iterate over the keys and delete them
    for (const request of requests) {
        await cache.delete(request);
    }
}

export {useAuth, AuthProvider};
