import React from "react";

import {Navbar} from "components/front-navbar/FrontNavbar";

import {MainPanel, FrontContentWrapper} from "./styledComponents";
import {Outlet} from "react-router-dom";

export const FrontContent: React.FC = () => {
    return (
        <MainPanel>
            <Navbar />
            <FrontContentWrapper>
                <Outlet />
            </FrontContentWrapper>
        </MainPanel>
    );
};
