import createTheme from "@mui/material/styles/createTheme";

// TODO: refactor colors, expand the color scheme perhaps

export const colors = {
    darkBlue: "#14213d",
    blue: "#29436d",
    lightBlue: "#008acb",
    offWhite: "#fafcff",
    background: "#e5f0ff",
    white: "#ffffff",
    black: "#000000",
    lightGray: "#f4f4f9",
    gray: "#e0e2f3",
    darkGray: "#767887",
    red: "#e85b4f",
    lightRed: "#fb3b2f",
    darkRed: "#991226",
    orange: "#eb8c10",
    yellow: "#f7d20f",
    green: "#56b07d",
    deepWalkBlue: "#2b4d97",
    purple: "#9b4ecf",
    pink: "#f8aed4",
};

export const roboto = "'Roboto Condensed', sans-serif";
export const lato = "'Lato', sans-serif";

declare module "@mui/material/styles" {
    interface Palette {
        deepWalkBlue: Palette["primary"];
        orange: Palette["primary"];
        midnightBlue: Palette["primary"];
    }

    interface PaletteOptions {
        deepWalkBlue?: PaletteOptions["primary"];
        orange?: PaletteOptions["primary"];
        midnightBlue?: PaletteOptions["primary"];
    }
}

export const theme = createTheme({
    spacing: 8,
    palette: {
        primary: {
            main: colors.deepWalkBlue,
            contrastText: colors.offWhite,
        },
        secondary: {
            main: colors.offWhite,
            contrastText: colors.darkBlue,
        },
        info: {
            main: colors.orange,
            contrastText: colors.offWhite,
        },
        text: {
            primary: colors.darkBlue,
            secondary: colors.white,
        },
        background: {
            default: colors.offWhite,
            paper: colors.white,
        },
        divider: colors.gray,
        deepWalkBlue: {
            main: "#448edc",
            light: "#76b9f0",
            dark: colors.deepWalkBlue,
        },
        midnightBlue: {
            main: colors.blue,
            light: "#94a1b6",
            dark: colors.darkBlue,
        },
        orange: {
            main: colors.orange,
            dark: "#fb730b",
            light: "#fcc21a",
        },
    },
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: colors.blue,
                    color: "#fcc21a",
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: "none",
                    padding: "8px 16px",
                    margin: "2px 0px",
                },
            },
        },
    },
    typography: {
        fontFamily: [lato, roboto].join(","),
        subtitle1: {
            color: "#76b9f0",
        },
        body1: {
            color: colors.darkBlue,
            fontFamily: lato,
        },
        body2: {
            color: colors.darkBlue,
            fontFamily: lato,
        },
        button: {
            textTransform: "none",
        },
        h1: {
            fontFamily: roboto,
        },
        h2: {
            fontFamily: roboto,
            fontSize: "2rem",
            margin: "15px 0px",
        },
        h3: {
            fontFamily: roboto,
        },
    },
});
