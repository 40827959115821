import React, {useEffect, useState} from "react";
import type {useMutation} from "@tanstack/react-query";
import {Button} from "@mui/material";
import {ConfirmDialog} from "react_ct/components/Confirm/ConfirmDialog";
import {DWAlert} from "react_ct/components/Alert/Alert";

export type MutationType<T> = ReturnType<typeof useMutation<void, unknown, T, unknown>>;

interface ConfirmMutationButtonProps<T> {
    mutation: MutationType<T>;
    mutationArg: T;
    confirmationTitle: string;
    confirmationDescription: string;
    buttonProps?: React.ComponentProps<typeof Button>;
    children?: React.ReactNode;
}

export const ConfirmMutationButton = <T,>({
    mutation,
    mutationArg,
    confirmationTitle,
    confirmationDescription,
    buttonProps,
    children,
}: ConfirmMutationButtonProps<T>): React.ReactElement => {
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState<"success" | "error">("success");

    const handleConfirm = (): void => {
        mutation.mutate(mutationArg);
        setConfirmOpen(false);
    };

    // TODO: we might be able to set up these alerts to automatically happen for all mutations and queries with QueryClient
    useEffect(() => {
        if (mutation.isSuccess) {
            setAlertMessage("Operation successful");
            setAlertSeverity("success");
            setAlertOpen(true);
        }
        if (mutation.isError) {
            setAlertMessage(String(mutation.error));
            setAlertSeverity("error");
            setAlertOpen(true);
        }
    }, [mutation.isSuccess, mutation.isError]);

    return (
        <>
            <Button {...buttonProps} onClick={() => setConfirmOpen(true)} disabled={mutation.isPending}>
                {children}
            </Button>
            <ConfirmDialog
                open={confirmOpen}
                setOpen={setConfirmOpen}
                title={confirmationTitle}
                description={confirmationDescription}
                onConfirm={handleConfirm}
            />
            <DWAlert
                openAlert={alertOpen}
                onClose={() => setAlertOpen(false)}
                alertMessage={alertMessage}
                alertSeverity={alertSeverity}
            />
        </>
    );
};
