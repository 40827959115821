import React from "react";
import {Outlet} from "react-router-dom";
import styled from "styled-components";

import {Layout} from "./layout/Layout";

export const MainPanel = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;
export const PortalContentWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

/**
 *
 * @returns Authenticated page content
 */
export const PortalContent: React.FC = () => {
    return (
        <Layout>
            <PortalContentWrapper>
                <Outlet />
            </PortalContentWrapper>
        </Layout>
    );
};
