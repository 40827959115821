import React from "react";
import {Box, Stack, Typography} from "@mui/material";
import horizontal_logo from "assets/White_Horizontal_TP.png";

export const Navbar: React.FC = () => {
    return (
        <Box
            height="100%"
            width="33vw"
            boxSizing="border-box"
            ml={-5}
            p={2}
            sx={theme => ({
                backgroundColor: theme.palette.primary.main,
                transform: "skew(-5deg)",
                boxShadow: "4px 0px 8px #00000044",
            })}>
            <Stack height="100%" gap={6} alignItems="center" justifyContent="center" sx={{transform: "skew(5deg)"}}>
                <Box ml={5} textAlign="center">
                    <Typography
                        variant="h2"
                        color="white"
                        component="h2"
                        sx={{
                            fontWeight: 500,
                            fontSize: "3rem",
                        }}>
                        Welcome to
                    </Typography>
                    <img src={horizontal_logo} width="75%" />
                </Box>
            </Stack>
        </Box>
    );
};
