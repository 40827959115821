import {BackupTable, ExpandMore} from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Collapse,
    Grid,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    darken,
} from "@mui/material";
import ClearWidthIcon from "assets/svg/ClearWidthIcon";
import CrossSlopeIcon from "assets/svg/CrossSlopeIcon";
import RunSlopeIcon from "assets/svg/RunSlopeIcon";
import {useProject} from "contexts/ProjectContext";
import {capitalizeFirstLetterOfEachWord, toProperCase} from "helpers/utils";
import React from "react";
import {
    EvalRegion,
    Obstruction,
    ReportType,
    ScoreThreshold,
    ScoreThresholdData,
    ViolationSeverity,
} from "react_ct/types";
import {ManualTagIcon} from "./ZoomCanvas/VerticalDisplacementOverlay";
import {regionColors} from "../constants";
import scoreThresholdData from "data/score_thresholds.json";

function LegendSubheading({children}: {children: React.ReactElement | string}): React.ReactElement {
    return (
        <Typography color="dimgray" fontSize="0.8rem">
            {children}
        </Typography>
    );
}

export function ScoreTable({threshold}: {threshold: ScoreThreshold}): React.ReactElement {
    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>
                            <Typography fontWeight="bold" fontSize="0.8rem" textAlign="center">
                                Cross Slope
                            </Typography>
                        </TableCell>
                        {threshold.run_slope && (
                            <TableCell>
                                <Typography fontWeight="bold" fontSize="0.8rem" textAlign="center">
                                    Run Slope
                                </Typography>
                            </TableCell>
                        )}
                        {threshold.width && (
                            <TableCell>
                                <Typography fontWeight="bold" fontSize="0.8rem" textAlign="center">
                                    Clear Width
                                </Typography>
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.values(ViolationSeverity).map(violation => (
                        <TableRow key={violation}>
                            <TableCell>
                                <Typography fontSize="0.9rem">{toProperCase(violation)}</Typography>
                            </TableCell>
                            <TableCell>
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <Box width="30px" height="30px">
                                        <CrossSlopeIcon {...{violation}} />
                                    </Box>
                                    <Typography fontSize="0.8rem" flexGrow={1}>
                                        {threshold.cross_slope[violation].filter(val => val > -1).join("-")}%
                                    </Typography>
                                </Stack>
                            </TableCell>
                            {threshold.run_slope && (
                                <TableCell>
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        <Box width="30px" height="30px">
                                            <RunSlopeIcon {...{violation}} />
                                        </Box>
                                        <Typography fontSize="0.8rem">
                                            {threshold.run_slope[violation].filter(val => val > -1).join("-")}%
                                        </Typography>
                                    </Stack>
                                </TableCell>
                            )}
                            {threshold.width && (
                                <TableCell>
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        <Box width="30px" height="30px">
                                            <ClearWidthIcon {...{violation}} />
                                        </Box>
                                        <Typography fontSize="0.8rem">
                                            {threshold.width[violation].filter(val => val > -1).join("-")}in
                                        </Typography>
                                    </Stack>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export function TableLegendComponent({
    evalData,
    showAggregatedRegions = false,
}: {
    evalData: ReportType;
    showAggregatedRegions?: boolean;
}): React.ReactElement {
    const {projectManualTags} = useProject();

    return (
        <>
            {" "}
            {showAggregatedRegions
                ? Object.entries(scoreThresholdData as ScoreThresholdData)
                      .filter(([key]) => key !== "none")
                      .map(([key, val]) =>
                          val.map((region, index) => (
                              <Accordion key={index} disableGutters>
                                  <AccordionSummary expandIcon={<ExpandMore />}>
                                      <LegendSubheading>
                                          {`${capitalizeFirstLetterOfEachWord(key)}${
                                              key === "ramp" && region.ramp_type
                                                  ? ": " +
                                                    String(
                                                        region.ramp_type
                                                            .map(type =>
                                                                capitalizeFirstLetterOfEachWord(
                                                                    type.replaceAll("_", " "),
                                                                ),
                                                            )
                                                            .join(", "),
                                                    )
                                                  : ""
                                          }`}
                                      </LegendSubheading>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                      <ScoreTable threshold={region} />
                                  </AccordionDetails>
                              </Accordion>
                          )),
                      )
                : scoreThresholdData.none.map((region, index) => (
                      <Accordion key={index} disableGutters>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                              <LegendSubheading>
                                  {region.labels
                                      .map(label => capitalizeFirstLetterOfEachWord(label.replaceAll("_", " ")))
                                      .join(", ")}
                              </LegendSubheading>
                          </AccordionSummary>
                          <AccordionDetails>
                              <ScoreTable threshold={region} />
                          </AccordionDetails>
                      </Accordion>
                  ))}
            <Accordion disableGutters>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <LegendSubheading>Manual Tags</LegendSubheading>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1}>
                        {projectManualTags
                            ?.filter(tag => !tag.archived)
                            .filter(tag =>
                                evalData?.regions?.map((region: EvalRegion) => region.label).includes(tag.name),
                            )
                            .map(manualTag => (
                                <Grid item key={manualTag.id}>
                                    <Stack direction="row" alignItems="baseline">
                                        <ManualTagIcon {...{manualTag}} iconSize={25} />
                                        <Typography fontSize="0.8rem">{manualTag.name}</Typography>
                                    </Stack>
                                </Grid>
                            ))}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion disableGutters>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <LegendSubheading>Regions</LegendSubheading>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        {Object.entries(regionColors)
                            .filter(([region]) => evalData?.regions?.map((r: EvalRegion) => r.label).includes(region))
                            .map(([region, color]) => (
                                <Grid item key={region}>
                                    <Box
                                        width="100%"
                                        height="100%"
                                        sx={{
                                            backgroundColor: String(color) + "33",
                                            borderRadius: theme => theme.shape.borderRadius,
                                            border: `1px solid ${String(color)}`,
                                            px: 1,
                                        }}>
                                        <Typography fontSize="0.8rem" color={darken(color, 0.2)}>
                                            {region}
                                        </Typography>
                                    </Box>
                                </Grid>
                            ))}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    );
}

export function TableLegend({
    evalData,
    showAggregatedRegions = false,
}: {
    evalData: ReportType;
    showAggregatedRegions?: boolean;
}): React.ReactElement {
    const [open, setOpen] = React.useState<boolean>(false);

    const handleChange = (): void => {
        setOpen(prev => !prev);
    };

    return (
        <React.Fragment>
            <Tooltip title="Icon key table" placement="left" arrow>
                <IconButton
                    disableRipple
                    aria-label="Open icon key table"
                    onClick={handleChange}
                    sx={theme => ({
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: "4px",
                    })}>
                    <BackupTable />
                </IconButton>
            </Tooltip>
            <Collapse in={open}>
                <Paper sx={{display: open ? "block" : "none", maxWidth: "450px"}}>
                    <TableLegendComponent {...{evalData, showAggregatedRegions}} />
                </Paper>
            </Collapse>
        </React.Fragment>
    );
}
