import {z} from "zod";

export const ScanBatchSchema = z.object({
    id: z.number(),
    annotator: z.object({
        annotatorId: z.number(),
        userId: z.number(),
        email: z.string(),
    }),
    reviewer: z
        .object({
            reviewerId: z.number(),
            userId: z.number(),
            email: z.string(),
        })
        .optional(),
    stages: z.array(z.string()),
    status: z.string(),
    scanMetadata: z.array(
        z.object({
            id: z.number(),
            notes: z.string().optional(),
            status: z.string(),
        }),
    ),
    projects: z.record(z.string(), z.string()),
    size: z.number(),
    createdAt: z.string().pipe(z.coerce.date()),
    updatedAt: z.string().pipe(z.coerce.date()),
    mileage: z.number().optional(),
    estimatedMileage: z.number().optional(),
    isReviewBatch: z.boolean(),
});

export type ScanBatch = z.infer<typeof ScanBatchSchema>;
