import React, {Fragment, type Dispatch, type SetStateAction} from "react";
import {ExpandMore} from "@mui/icons-material";
import {Box, Stack, Typography, Accordion, AccordionSummary, AccordionDetails} from "@mui/material";
import {SelectSingle} from "react_ct/components";
import {type ReportType, type Project} from "react_ct/types";
import {SCORE_COLORS, tableContents} from "./constants";
import {colors} from "react_ct/theme";
import imageLegend from "assets/PopUp_Legend.png";

/**
 *
 * @param report The report to get the score color for
 * @returns A color string corresponding with the accessibility score of the report
 */
export const evalScoreToColor = (evalData: ReportType): string => {
    if (evalData?.accessibility_grade !== undefined) {
        return SCORE_COLORS[evalData.accessibility_grade];
    } else {
        return colors.gray;
    }
};
/**
 *
 * @param report The report to get the score color for
 * @returns A color string corresponding with the accessibility score of the report
 */
export const scoreToColor = (report: ReportType): string => {
    if (report.accessibility_grade !== undefined) {
        return SCORE_COLORS[report.accessibility_grade];
    } else {
        return colors.gray;
    }
};

export const dwStatusToColor = (report: ReportType): string => {
    if (!report.detectable_warning) {
        return colors.gray;
    }
    if (report.detectable_warning.toLowerCase() === "missing detectable warning") {
        return colors.darkRed;
    } else if (report.detectable_warning.toLowerCase() === "truncated domes") {
        return colors.green;
    } else if (report.detectable_warning.toLowerCase() === "no dw necessary") {
        return colors.blue;
    } else {
        return colors.gray;
    }
};

/**
 *
 * @returns A table containing the accessibility violations legend
 */
export const ImageLegend: React.FC = () => {
    const ImageLegendTable: React.FC = () => {
        return (
            <Fragment>
                <img src={imageLegend} width={300} />
            </Fragment>
        );
    };

    return (
        <Accordion
            disableGutters
            elevation={0}
            sx={theme => ({
                width: "60%",
                position: "absolute",
                right: 0,
                marginRight: "8px",
                zIndex: 10,
                borderBottomLeftRadius: theme.shape.borderRadius,
                boxShadow: "-4px 4px 4px #00000033",
                outline: `2px solid ${theme.palette.background.paper}`,
                "&.MuiAccordion-root": {
                    "&:before": {
                        backgroundColor: "transparent",
                    },
                },
            })}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                    border: "none",
                    "&.MuiAccordionSummary-content": {
                        margin: 0,
                    },
                }}>
                <Typography fontWeight="bold" fontSize="0.9rem">
                    View Image Legend
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <ImageLegendTable />
            </AccordionDetails>
        </Accordion>
    );
};

export function hexToHSL(hex: string): {
    fullHSL: string;
    hue: number;
    saturation: number;
    lum: number;
    alpha: number;
} {
    // Convert hex to RGB first
    let r: string | number = 0;
    let g: string | number = 0;
    let b: string | number = 0;
    let a: string | number = 1;
    if (hex.length === 5) {
        r = "0x" + hex[1] + hex[1];
        g = "0x" + hex[2] + hex[2];
        b = "0x" + hex[3] + hex[3];
        a = "0x" + hex[4] + hex[4];
    } else if (hex.length === 9) {
        r = "0x" + hex[1] + hex[2];
        g = "0x" + hex[3] + hex[4];
        b = "0x" + hex[5] + hex[6];
        a = "0x" + hex[7] + hex[8];
    }

    // Then to HSL
    r = (r as number) / 255;
    g = (g as number) / 255;
    b = (b as number) / 255;
    a = ((a as number) / 255).toFixed(3);

    const cmin = Math.min(r, g, b);
    const cmax = Math.max(r, g, b);
    const delta = cmax - cmin;

    let h = 0;
    let s = 0;
    let l = 0;

    h = delta === 0 ? 0 : cmax === r ? (g - b) / delta : cmax === g ? (b - r) / delta + 2 : (r - g) / delta + 4;

    h = Math.round(h * 60);
    if (h < 0) {
        h += 360;
    }

    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return {
        fullHSL: `hsl(${h}, ${s}%, ${l}%, ${a})`,
        hue: h,
        saturation: s,
        lum: l,
        alpha: parseInt(a),
    };
}

export function hslaToHexA(h: number, s: number, l: number, a: number): string {
    // Convert HSLA to RGBA
    let r: number, g: number, b: number;
    h /= 360;
    s /= 100;
    l /= 100;

    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs(((h * 6) % 2) - 1));
    const m = l - c / 2;

    if (h < 1 / 6) {
        r = c;
        g = x;
        b = 0;
    } else if (h < 1 / 3) {
        r = x;
        g = c;
        b = 0;
    } else if (h < 0.5) {
        r = 0;
        g = c;
        b = x;
    } else if (h < 2 / 3) {
        r = 0;
        g = x;
        b = c;
    } else if (h < 5 / 6) {
        r = x;
        g = 0;
        b = c;
    } else {
        r = c;
        g = 0;
        b = x;
    }

    // Convert RGBA to Hexadecimal
    const toHex = (num: number): string => {
        const hex = Math.round(num * 255).toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    };

    let hex = "#" + toHex(r + m) + toHex(g + m) + toHex(b + m);

    // Alpha value
    let alpha = Math.round(a * 255).toString(16);
    if (alpha.length === 1) alpha = "0" + alpha;

    hex += alpha;

    return hex;
}

/**
 *
 * @param props The access score indicator props
 * @returns An indicator showing the access score for the current scan
 */
export function AccessScoreIndicator({evalData}: {evalData: ReportType}) {
    const color = evalScoreToColor(evalData);
    const hslColor = hexToHSL(`${color}ff`);
    const description =
        tableContents.filter(content => content.score === evalData?.accessibility_grade)?.[0]?.description ||
        tableContents[tableContents.length - 1].description;

    return (
        <Stack
            direction="row"
            gap={1}
            flexGrow={1}
            alignItems="center"
            sx={{
                backgroundColor: `${color}44`,
                borderRadius: 50,
                py: 1,
                pl: 1,
                pr: 4,
                boxSizing: "border-box",
                border: `2px solid ${color}44`,
                borderLeftWidth: "1px",
                borderRightWidth: "1px",
                borderTop: "none",
            }}>
            <Box
                width="36px"
                height="36px"
                borderRadius="50%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                    backgroundColor: `hsl(${hslColor.hue - 3}, ${hslColor.saturation}%, ${hslColor.lum - 8}%, ${
                        hslColor.alpha
                    })`,
                }}>
                <Typography
                    sx={theme => ({
                        cursor: "default",
                        color: "#ffffffee",
                        fontSize: "1.5rem",
                        fontWeight: theme.typography.fontWeightBold,
                        textAlign: "center",
                    })}>
                    {evalData?.accessibility_grade}
                </Typography>
            </Box>
            <Stack direction="row" alignItems="baseline" spacing={1} ml={1}>
                <Typography
                    sx={theme => ({
                        color: `hsl(${hslColor.hue - 2}, ${hslColor.saturation}%, ${hslColor.lum - 22}%, ${
                            hslColor.alpha
                        })`,
                        fontWeight: theme.typography.fontWeightBold,
                    })}>
                    {evalData?.access_description ?? description}
                </Typography>
            </Stack>
        </Stack>
    );
}

/**
 *
 * @param props The change project dropdown props
 * @returns A dropdown to switch between projects
 */
export const ChangeProjectDropdown: React.FC<{
    projects: Project[] | undefined;
    currentProject: Project | undefined;
    setCurrentProject: Dispatch<SetStateAction<Project | undefined>>;
}> = props => {
    const {projects, currentProject, setCurrentProject} = props;

    return (
        <SelectSingle<Project>
            label="Project"
            options={projects ?? []}
            dataKey="name"
            selectedOption={currentProject}
            setSelectedOption={setCurrentProject}
        />
    );
};
