import React from "react";

import Select, {type SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

import {type Theme, type SxProps} from "@mui/material/styles";

interface SelectSingleProps<T> {
    /** True if the select box is open, false if it is closed */
    open?: boolean;
    /** The select box label */
    label: string;
    /** Array of select box options */
    options: T[];
    /** True if the select box is open, false if it is closed */
    onOpen?: (event: React.SyntheticEvent<Element, Event>) => void;
    /** Function that sets the open state of the select box */
    onClose?: (event: React.SyntheticEvent<Element, Event>) => void;
    /** Currently selected option */
    selectedOption?: T;
    /** The key for a particular option, must be a unique string */
    dataKey: keyof T; // T[key] must be string
    /** Function that sets the selected option */
    setSelectedOption: (option: T) => void;
    /** The MUI sx props for the select box */
    sx?: SxProps<Theme>;
}
/**
 *
 * @param props {SelectSingleProps<T>} The props for the SelectSingleProps component
 * @returns {React.ReactElement} A select box that allows for a single option to be selected
 */
export const SelectSingle = <T,>(props: SelectSingleProps<T>): React.ReactElement => {
    const {label, options, selectedOption, setSelectedOption, dataKey, sx, open, onOpen, onClose} = props;

    return (
        <FormControl sx={{width: "15rem"}}>
            <InputLabel
                id={`${label}-select-label`}
                sx={theme => ({
                    fontSize: "0.8rem",
                    "&.MuiInputLabel-shrink": {
                        transform: "translate(7px, -10px) scale(1)",
                        px: 2,
                        backgroundColor: theme.palette.deepWalkBlue.dark,
                        color: theme.palette.text.secondary,
                        borderRadius: theme.shape.borderRadius,
                        fontWeight: theme.typography.fontWeightBold,
                        fontSize: "0.9rem",
                    },
                })}>
                {label}
            </InputLabel>
            <Select
                open={open}
                onOpen={onOpen}
                onClose={onClose}
                sx={sx}
                label={label}
                labelId={`${label}-select-label`}
                id={`${label}-select`}
                value={selectedOption ? (selectedOption[dataKey] as string) : ""}
                onChange={(event: SelectChangeEvent<string>) => {
                    const selectedValue = event.target.value;
                    const selectedOption = options?.find(el => (el[dataKey] as string) === selectedValue);
                    if (selectedOption) {
                        setSelectedOption(selectedOption);
                    }
                }}
                renderValue={selected => selected}>
                {options && options.length > 0 ? (
                    options?.map(option => (
                        <MenuItem key={option[dataKey] as string} value={option[dataKey] as string}>
                            {option[dataKey] as string}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem disabled>No options available</MenuItem>
                )}
            </Select>
        </FormControl>
    );
};
