import React from "react";
import {Button, Typography, Box, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";

const NotFoundPage: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const handleClick = (): void => {
        // Implement redirect logic here
        navigate("/portal");
    };

    return (
        <Box height="100vh" display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap={2}>
            <Typography variant="h1">404</Typography>
            <Typography color={theme.palette.midnightBlue.light}>Page Not Found</Typography>
            <Button variant="contained" color="primary" onClick={handleClick}>
                Go Home
            </Button>
        </Box>
    );
};

export default NotFoundPage;
