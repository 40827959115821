import {Warning} from "@mui/icons-material";
import {Box, Stack, Typography} from "@mui/material";
import React from "react";
import {useRouteError} from "react-router-dom";
import {theme} from "react_ct/theme";

const LoadingError: React.FC<{
    errorMessage?: string;
}> = props => {
    const {errorMessage} = props;
    const error: Error = useRouteError() as Error;
    return (
        <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
            <Box textAlign="center">
                <Box>
                    <Typography variant="h1" color={theme.palette.primary.main}>
                        <Warning style={{fontSize: 80}} />
                    </Typography>
                </Box>
                <Stack spacing={1}>
                    <Typography component="h3" variant="h5" fontWeight="bold">
                        Oops! Something went wrong.
                    </Typography>
                    {errorMessage && <Typography>{errorMessage}</Typography>}
                    {error && <Typography>{error.message}</Typography>}
                </Stack>
            </Box>
        </Box>
    );
};

export default LoadingError;
