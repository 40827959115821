import React from "react";
import {AggregatedLandingRegion, AggregatedRampRegion, AggregatedRegion} from "react_ct/types";
import {HtmlTooltip} from "./HtmlTooltip";
import {rgbToHex} from "@mui/material/styles";
import {colors} from "react_ct/theme";
import {Box, Divider, Stack, Typography} from "@mui/material";
import {capitalizeFirstLetterOfEachWord, toProperCase} from "helpers/utils";
import {measurementUnits} from "../../constants";
import {ErrorOutline} from "@mui/icons-material";

function AggregatedRegionShape({
    region,
    imgRatio,
    selectedCanvasItemId,
    setSelectedCanvasItemId,
    correspondingLanding,
}: {
    region: AggregatedRegion;
    imgRatio: number;
    selectedCanvasItemId: string | null;
    setSelectedCanvasItemId: React.Dispatch<React.SetStateAction<string | null>>;
    correspondingLanding: AggregatedLandingRegion | undefined;
}): React.ReactElement {
    const [openTooltip, setOpenTooltip] = React.useState(false);
    const itemId = `agg_region-${String(region.id)}`;

    const onClick = (): void => {
        setSelectedCanvasItemId(itemId);
    };

    const onOpen = (): void => {
        setOpenTooltip(true);
    };

    const onClose = (): void => {
        setOpenTooltip(false);
    };

    const polygonPoints =
        correspondingLanding && (region as AggregatedRampRegion).landing_points
            ? [
                  `M ${(region as AggregatedRampRegion).landing_points[0][0] * imgRatio} ${
                      (region as AggregatedRampRegion).landing_points[0][1] * imgRatio
                  }`,
                  ...(region as AggregatedRampRegion).landing_points.map(
                      point => `L${point[0] * imgRatio} ${point[1] * imgRatio}`,
                  ),
                  `L ${(region as AggregatedRampRegion).landing_points[0][0] * imgRatio} ${
                      (region as AggregatedRampRegion).landing_points[0][1] * imgRatio
                  }`,
              ].join(" ")
            : [
                  `M ${region.points[0][0] * imgRatio} ${region.points[0][1] * imgRatio}`,
                  ...region.points.map(point => `L${point[0] * imgRatio} ${point[1] * imgRatio}`),
                  `L ${region.points[0][0] * imgRatio} ${region.points[0][1] * imgRatio}`,
              ].join(" ");

    const hasProwagException =
        (region as AggregatedRampRegion).exceptions &&
        Object.keys((region as AggregatedRampRegion).exceptions ?? {}).length &&
        Object.values((region as AggregatedRampRegion).exceptions ?? {}).some(val => val);

    const isRampLanding = region.aggregated_region_type === "ramp" && correspondingLanding;

    return (
        <HtmlTooltip
            followCursor
            placement="top"
            id={itemId}
            open={openTooltip}
            disableHoverListener={selectedCanvasItemId === itemId}
            title={
                <Stack>
                    <Typography fontWeight="bold">
                        {isRampLanding ? "Ramp/Landing" : toProperCase(region.aggregated_region_type)}
                    </Typography>
                    {(region as AggregatedRampRegion | AggregatedLandingRegion).measurements && (
                        <>
                            {isRampLanding && (
                                <Typography color="dimgray" fontSize="0.8rem" mt={1}>
                                    Ramp
                                </Typography>
                            )}
                            <Stack>
                                {Object.entries((region as AggregatedRampRegion | AggregatedLandingRegion).measurements)
                                    .filter(([, val]) => val.value > -1)
                                    .map(([key, val]) => {
                                        return (
                                            <Stack key={key} direction="row" gap={1}>
                                                <Typography>
                                                    {capitalizeFirstLetterOfEachWord(key.split("_").join(" "))}:
                                                </Typography>
                                                <Typography textAlign="right">
                                                    {val.value.toFixed(1)}{" "}
                                                    {measurementUnits[val.unit as keyof typeof measurementUnits]}
                                                </Typography>
                                            </Stack>
                                        );
                                    })}
                                {correspondingLanding?.measurements && (
                                    <Box>
                                        <Typography color="dimgray" fontSize="0.8rem" mt={1}>
                                            Landing
                                        </Typography>
                                        {Object.entries(correspondingLanding.measurements).map(([key, val]) => {
                                            return (
                                                <Stack key={key} direction="row" gap={1}>
                                                    <Typography>
                                                        {capitalizeFirstLetterOfEachWord(key.split("_").join(" "))}:
                                                    </Typography>
                                                    <Typography textAlign="right">
                                                        {val.value.toFixed(1)}{" "}
                                                        {
                                                            measurementUnits[
                                                                val.unit.toUpperCase() as keyof typeof measurementUnits
                                                            ]
                                                        }
                                                    </Typography>
                                                </Stack>
                                            );
                                        })}
                                    </Box>
                                )}
                            </Stack>
                        </>
                    )}
                    {hasProwagException && (
                        <Box>
                            <Divider sx={{my: 1}} />
                            <Stack px={1}>
                                {Object.entries((region as AggregatedRampRegion).exceptions ?? {})
                                    .filter(([, val]) => val)
                                    .map(([exception]) => {
                                        return (
                                            <Stack direction="row" key={exception} gap={1} alignItems="center">
                                                <ErrorOutline color="info" fontSize="small" />
                                                <Typography fontSize="0.8rem">
                                                    {capitalizeFirstLetterOfEachWord(exception.replaceAll("_", " "))}
                                                </Typography>
                                            </Stack>
                                        );
                                    })}
                            </Stack>
                        </Box>
                    )}
                </Stack>
            }
            {...{onClick, onOpen, onClose}}>
            <path
                style={{
                    cursor: "pointer",
                    fill: region.aggregated_region_type === "ramp" ? colors.orange + "55" : "#ffffff00",
                    transition: "fill 0.1s",
                }}
                onMouseEnter={event => {
                    const color = rgbToHex((event.target as SVGPathElement).style.fill);
                    (event.target as SVGPathElement).style.fill = color.substring(0, color.length - 2) + "66";
                }}
                onMouseLeave={event => {
                    const color = rgbToHex((event.target as SVGPathElement).style.fill);
                    (event.target as SVGPathElement).style.fill = color.substring(0, color.length - 2) + "33";
                }}
                stroke={region.aggregated_region_type === "ramp" ? colors.orange : colors.lightGray}
                strokeWidth={hasProwagException ? 8 * imgRatio : 4 * imgRatio}
                d={polygonPoints}
            />
        </HtmlTooltip>
    );
}

export default function AggregatedRegionOverlay({
    aggregatedRegions,
    imgRatio,
    selectedCanvasItemId,
    setSelectedCanvasItemId,
}: {
    aggregatedRegions: AggregatedRegion[];
    imgRatio: number;
    selectedCanvasItemId: string | null;
    setSelectedCanvasItemId: React.Dispatch<React.SetStateAction<string | null>>;
}): React.ReactElement {
    const landingRegionsWithRamp: AggregatedRegion[] = aggregatedRegions.filter(region => {
        return (
            region.aggregated_region_type === "landing" &&
            aggregatedRegions.find(
                r =>
                    r.aggregated_region_type === "ramp" &&
                    !r.points.length &&
                    r.landing_points.length &&
                    r.landings.includes(region.landing_regions[0]),
            )
        );
    });
    const filteredRegions = aggregatedRegions.filter(
        region => !landingRegionsWithRamp.find(landing => landing.id === region.id),
    );
    return (
        <svg width="100%" height="100%" style={{position: "absolute"}}>
            {filteredRegions
                .sort((a, b) => {
                    if (a.aggregated_region_type === "ramp") return 1;
                    else if (b.aggregated_region_type === "ramp") return -1;
                    else return 0;
                })
                .map(region => {
                    let correspondingLanding: AggregatedLandingRegion | undefined;
                    if (
                        (region as AggregatedRampRegion).landing_points?.length &&
                        !(region as AggregatedRampRegion).points.length
                    ) {
                        // if there are no regular points, then it overlaps with a landing
                        const foundLanding = aggregatedRegions
                            .filter(r => r.aggregated_region_type === "landing")
                            .find(landing =>
                                (landing as AggregatedLandingRegion).landing_regions.some(p =>
                                    (region as AggregatedRampRegion).landings.includes(p),
                                ),
                            );
                        if (foundLanding) {
                            correspondingLanding = foundLanding as AggregatedLandingRegion;
                        }
                    }
                    return (
                        <AggregatedRegionShape
                            key={region.id}
                            {...{region, imgRatio, selectedCanvasItemId, setSelectedCanvasItemId, correspondingLanding}}
                        />
                    );
                })}
        </svg>
    );
}
