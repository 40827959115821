import React from "react";
import {
    Box,
    Grid,
    type GridSize,
    Paper,
    darken,
    Tabs,
    Tab,
    tabClasses,
    Stack,
    Typography,
    type SxProps,
    SpeedDial,
    speedDialClasses,
    SpeedDialAction,
    speedDialActionClasses,
    Snackbar,
    AlertProps,
    Alert,
    lighten,
} from "@mui/material";
import {useProject} from "contexts/ProjectContext";
import {useQuery} from "@tanstack/react-query";
import {geojsonKeys, getGeojsonByType, projectKeys} from "queries/queries";
import type {IndividualFeatureProperties, PanelFeature, ReportType} from "react_ct/types";
import {colors, theme} from "react_ct/theme";
import MileageDashboard from "./components/MileageDashboard";
import CollectionDashboard from "./components/CollectionDashboard";
import LoadingScreen from "components/LoadingScreen";
import PointsDashboard from "./components/PointsDashboard";
import {Save, SaveAlt, SaveAs} from "@mui/icons-material";
import {apiRequest} from "react_ct/requests";
import {
    DeleteDashboardViewModal,
    OverwriteDashboardViewModal,
    SaveDashboardViewModal,
} from "./components/DashboardModals";
import {SavedDashboards} from "./components/SavedDashboardViews";
import {useSearchParams} from "react-router-dom";

export interface DashboardView {
    id: number;
    name: string;
    category: string;
    scanFeatureIds?: number[];
    polygonSelectCoordinates?: GeoJSON.Position[][][];
}

export function GridItem({
    xs,
    sm,
    md,
    lg,
    xl,
    title,
    children,
    p = true,
    gridItemSx,
    paperSx,
}: {
    xs?: boolean | GridSize;
    sm?: boolean | GridSize;
    md?: boolean | GridSize;
    lg?: boolean | GridSize;
    xl?: boolean | GridSize;
    title?: string;
    children?: string | JSX.Element | JSX.Element[];
    p?: boolean;
    gridItemSx?: SxProps;
    paperSx?: SxProps;
}): React.ReactElement {
    return (
        <Grid item {...{xs, sm, md, lg, xl}} sx={gridItemSx}>
            <Paper
                sx={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    boxSizing: "border-box",
                    p: p ? 4 : 0,
                    ...paperSx,
                }}>
                {title && <Typography mb={1}>{title}</Typography>}
                {children}
            </Paper>
        </Grid>
    );
}

function CustomTabPanel(props: {
    children?: React.ReactNode;
    index: number;
    value: number;
    selectedDashboardView?: boolean;
}): React.ReactElement {
    const {children, value, index, selectedDashboardView, ...other} = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            sx={{
                backgroundColor: theme.palette.secondary.main,
            }}
            borderRadius={selectedDashboardView ? 2 : 0}
            {...other}>
            {value === index && <Box>{children}</Box>}
        </Box>
    );
}

export default function HomePage(): React.ReactElement {
    const {
        currentProject: project,
        projectsError,
        projectScans,
        projectScansError,
        areProjectsPending,
        projectManualTags,
    } = useProject();

    const {data: baseData, error: baseDataError} = useQuery({
        queryKey: geojsonKeys.featuresGeojson(project?.id),
        queryFn: async ({queryKey}) => {
            return await getGeojsonByType(...(queryKey as [number | undefined, string, string]));
        },
        enabled: !!project,
    });

    const {data: indvData, error: indvDataError} = useQuery({
        queryKey: geojsonKeys.individualFeaturesGeojson(project?.id),
        enabled: !!project,
        queryFn: async ({queryKey}) => {
            return await getGeojsonByType(...(queryKey as [number | undefined, string, string]));
        },
    });

    const {data: obsData} = useQuery({
        queryKey: geojsonKeys.obstructionsGeojson(project?.id),
        enabled: !!project,
        queryFn: async ({queryKey}) => {
            return await getGeojsonByType(...(queryKey as [number | undefined, string, string]));
        },
    });

    const {data: savedDashboards} = useQuery({
        queryKey: projectKeys.projectDashboards(project?.id),
        enabled: !!project,
        queryFn: async () => {
            const res = await apiRequest({path: `project/${project?.id ?? 0}/dashboards`});
            const data: DashboardView[] = res.data;
            return data;
        },
    });

    const baseGeojson: GeoJSON.FeatureCollection<GeoJSON.LineString, ReportType> | undefined = baseData?.geojsonData as
        | GeoJSON.FeatureCollection<GeoJSON.LineString, ReportType>
        | undefined;
    const indvGeojson: GeoJSON.FeatureCollection<GeoJSON.Point, IndividualFeatureProperties> | undefined =
        indvData?.geojsonData as GeoJSON.FeatureCollection<GeoJSON.Point, IndividualFeatureProperties> | undefined;
    const obsGeojson: GeoJSON.FeatureCollection<GeoJSON.Point | GeoJSON.LineString, PanelFeature> | undefined =
        obsData?.geojsonData as GeoJSON.FeatureCollection<GeoJSON.Point | GeoJSON.LineString, PanelFeature> | undefined;

    const dashboards = ["midblocks", "curb_ramps", "collection", "points"];

    const [searchParams, setSearchParams] = useSearchParams();

    const [currentDashboard, setCurrentDashboard] = React.useState(
        !dashboards.includes(searchParams.get("dashboard") ?? "")
            ? 0
            : dashboards.indexOf(searchParams.get("dashboard") ?? ""),
    );
    const [currentDashboardView, setCurrentDashboardView] = React.useState<DashboardView>();
    const [dashboardToDelete, setDashboardToDelete] = React.useState<DashboardView>();
    const [openSaveMenu, setOpenSaveMenu] = React.useState(false);
    const handleOpen = (): void => setOpenSaveMenu(true);
    const handleClose = (): void => setOpenSaveMenu(false);
    const [openSaveModal, setOpenSaveModal] = React.useState(false);
    const [openOverwriteModal, setOpenOverwriteModal] = React.useState(false);
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const [currentMapFeatures, setCurrentMapFeatures] = React.useState<
        Array<GeoJSON.Feature<GeoJSON.LineString, ReportType>>
    >([]);
    const [freeSelectRange, setFreeSelectRange] = React.useState<GeoJSON.Feature[]>([]);
    const [alert, setAlert] = React.useState<{message: string; severity: AlertProps["severity"]}>();

    const a11yProps = (
        index: number,
    ): {
        id: string;
        "aria-controls": string;
    } => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    };

    const changeDashboard = (event: React.SyntheticEvent, newValue: number): void => {
        setCurrentDashboard(newValue);
    };

    const saveActions = [
        {
            icon: <SaveAs color="primary" />,
            name: "Save new selected region...",
            onClick: () => setOpenSaveModal(true),
        },
        {
            icon: <SaveAlt color="primary" />,
            name: "Save selected region...",
            onClick: () => setOpenOverwriteModal(true),
        },
    ];

    React.useEffect(() => {
        if (searchParams.get("dashboard") !== dashboards[currentDashboard])
            setSearchParams({dashboard: dashboards[currentDashboard]});
    }, [currentDashboard]);

    React.useEffect(() => {
        if (searchParams.get("dashboard") !== dashboards[currentDashboard])
            setCurrentDashboard(
                dashboards.includes(searchParams.get("dashboard") ?? "")
                    ? dashboards.indexOf(searchParams.get("dashboard") ?? "")
                    : 0,
            );
    }, [searchParams]);

    React.useEffect(() => {
        if (!!dashboardToDelete && !openDeleteModal) {
            setOpenDeleteModal(true);
        }
    }, [dashboardToDelete]);

    React.useEffect(() => {
        if (!openDeleteModal) {
            setDashboardToDelete(undefined);
        }
    }, [openDeleteModal]);

    React.useEffect(() => {
        if (currentDashboardView?.polygonSelectCoordinates) {
            setFreeSelectRange(
                currentDashboardView.polygonSelectCoordinates?.map((coords, index) => ({
                    type: "Feature",
                    geometry: {
                        type: "Polygon",
                        coordinates: coords,
                    },
                    properties: {
                        id: index,
                    },
                })),
            );
        } else {
            setFreeSelectRange([]);
        }
    }, [currentDashboardView]);

    if (projectsError && !project)
        return (
            <Box>
                <Typography>Could not retrieve projects</Typography>
            </Box>
        );

    if (!project && areProjectsPending)
        return (
            <Box>
                <LoadingScreen />
            </Box>
        );

    return (
        <>
            <Snackbar open={!!alert} autoHideDuration={4000} onClose={() => setAlert(undefined)}>
                <Alert onClose={() => setAlert(undefined)} severity={alert?.severity}>
                    {alert?.message}
                </Alert>
            </Snackbar>
            <SaveDashboardViewModal
                {...{
                    openSaveModal,
                    setOpenSaveModal,
                    setAlert,
                    currentMapFeatures,
                    freeSelectRange,
                    setCurrentDashboardView,
                }}
                projectId={project?.id ?? 0}
                currentCategory={dashboards[currentDashboard]}
            />
            <OverwriteDashboardViewModal
                {...{openOverwriteModal, setOpenOverwriteModal, setAlert, currentMapFeatures, freeSelectRange}}
                projectId={project?.id ?? 0}
                savedDashboardViews={savedDashboards?.filter(
                    dashboard => dashboard.category === dashboards[currentDashboard],
                )}
            />
            {dashboardToDelete && (
                <DeleteDashboardViewModal
                    {...{openDeleteModal, setOpenDeleteModal, setAlert, dashboardToDelete}}
                    projectId={project?.id ?? 0}
                />
            )}
            <Box
                id="page-container"
                width="100%"
                height="auto"
                minHeight="100vh"
                boxSizing="border-box"
                position="relative"
                sx={{
                    overflowX: "hidden",
                    backgroundColor: theme =>
                        !!currentDashboardView && dashboards[currentDashboard] !== "collection"
                            ? lighten(theme.palette.primary.light, 0.8)
                            : theme.palette.secondary.main,
                }}>
                <SpeedDial
                    ariaLabel="Save dashboard"
                    sx={{
                        position: "fixed",
                        bottom: 24,
                        right: 24,
                        [`& .${speedDialClasses.fab}`]: {
                            p: 6,
                        },
                    }}
                    icon={<Save fontSize="large" />}
                    open={openSaveMenu}
                    hidden={dashboards[currentDashboard] === "collection" || !currentMapFeatures.length}
                    onOpen={handleOpen}
                    onClose={handleClose}>
                    {saveActions.map(action => (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            onClick={action.onClick}
                            tooltipOpen
                            sx={{
                                my: 1,
                                [`& .${speedDialActionClasses.staticTooltipLabel}`]: {
                                    display: "block",
                                    color: theme.palette.text.secondary,
                                    backgroundColor: colors.darkBlue + "ee",
                                    width: "16vw",
                                    wordBreak: "normal",
                                },
                            }}
                        />
                    ))}
                </SpeedDial>
                <Paper elevation={2} sx={{width: "100%", px: 6, pt: 1, borderRadius: 0}}>
                    <Stack direction="row" alignItems="baseline">
                        <Tabs value={currentDashboard} onChange={changeDashboard} sx={{flexGrow: 1}}>
                            <Tab
                                label="Sidewalk"
                                sx={{
                                    [`&.${tabClasses.textColorPrimary}:not(.${tabClasses.selected})`]: {
                                        color: darken(colors.gray, 0.2),
                                    },
                                }}
                                {...a11yProps(0)}
                            />
                            <Tab
                                label="Curb Ramps"
                                sx={{
                                    [`&.${tabClasses.textColorPrimary}:not(.${tabClasses.selected})`]: {
                                        color: darken(colors.gray, 0.2),
                                    },
                                }}
                                {...a11yProps(0)}
                            />
                            <Tab
                                label="Collection"
                                sx={{
                                    [`&.${tabClasses.textColorPrimary}:not(.${tabClasses.selected})`]: {
                                        color: darken(colors.gray, 0.2),
                                    },
                                }}
                                {...a11yProps(1)}
                            />
                            {project?.id === 39 && (
                                <Tab
                                    label="Points"
                                    sx={{
                                        [`&.${tabClasses.textColorPrimary}:not(.${tabClasses.selected})`]: {
                                            color: darken(colors.gray, 0.2),
                                        },
                                    }}
                                    {...a11yProps(2)}
                                />
                            )}
                        </Tabs>
                        <Typography
                            component="h2"
                            variant="h5"
                            flexBasis="33%"
                            sx={{overflow: "hidden", textWrap: "nowrap", textOverflow: "ellipsis"}}>
                            {project?.name}
                        </Typography>
                    </Stack>
                </Paper>
                <Box sx={{p: 3}}>
                    {savedDashboards && savedDashboards.length > 0 && dashboards[currentDashboard] !== "collection" && (
                        <SavedDashboards
                            currentDashboard={dashboards[currentDashboard]}
                            dashboards={savedDashboards}
                            {...{
                                setCurrentDashboardView,
                                currentDashboardView,
                                setOpenDeleteModal,
                                setDashboardToDelete,
                            }}
                        />
                    )}
                    <CustomTabPanel
                        value={currentDashboard}
                        index={0}
                        selectedDashboardView={!!currentDashboardView && dashboards[currentDashboard] !== "collection"}>
                        <MileageDashboard
                            {...{
                                baseGeojson,
                                baseDataError,
                                obsGeojson,
                                projectScans,
                                projectScansError,
                                setCurrentMapFeatures,
                                freeSelectRange,
                                setFreeSelectRange,
                                currentDashboardView,
                            }}
                            filterOptions="midblock"
                        />
                    </CustomTabPanel>
                    <CustomTabPanel
                        value={currentDashboard}
                        index={1}
                        selectedDashboardView={!!currentDashboardView && dashboards[currentDashboard] !== "collection"}>
                        <MileageDashboard
                            {...{
                                baseGeojson,
                                baseDataError,
                                obsGeojson,
                                projectScans,
                                projectScansError,
                                setCurrentMapFeatures,
                                freeSelectRange,
                                setFreeSelectRange,
                                currentDashboardView,
                            }}
                            filterOptions="curb ramp"
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={currentDashboard} index={2}>
                        {project !== undefined && <CollectionDashboard project={project} />}
                    </CustomTabPanel>
                    {project?.id === 39 && (
                        <CustomTabPanel value={currentDashboard} index={3}>
                            <PointsDashboard
                                {...{
                                    indvGeojson,
                                    indvDataError,
                                    projectManualTags,
                                }}
                            />
                        </CustomTabPanel>
                    )}
                </Box>
            </Box>
        </>
    );
}
