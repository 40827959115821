import React from "react";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {type Theme, type SxProps} from "@mui/material/styles";
import {type SelectChangeEvent} from "@mui/material/Select";

interface SelectMultipleProps<T> {
    /** True if the select box is open, false if it is closed */
    open?: boolean;
    /** The select box label */
    label: string;
    /** Array of select box options */
    options: T[];
    /** True if the select box is open, false if it is closed */
    onOpen?: (event: React.SyntheticEvent<Element, Event>) => void;
    /** Function that sets the open state of the select box */
    onClose?: (event: React.SyntheticEvent<Element, Event>) => void;
    /** Array of currently selected options */
    selectedOptions?: T[];
    /** The key for a particular option, must be a unique string */
    dataKey: keyof T; // value of T[key] must be string and must be unique
    /** Function that sets the selected options */
    setSelectedOptions: (options: T[]) => void;
    /** The MUI sx props for the select box */
    sx?: SxProps<Theme>;
}

/**
 *
 * @param props {SelectMultipleProps<T>} The props for the SelectMultiple component
 * @returns {React.ReactElement} A select box that allows for multiple options to be selected
 */
export const SelectMultiple = <T,>(props: SelectMultipleProps<T>): React.ReactElement => {
    const {label, options, selectedOptions, setSelectedOptions, dataKey, sx, open, onClose, onOpen} = props;

    return (
        <FormControl sx={{width: "15rem"}}>
            <InputLabel
                id={`${label}-select-label`}
                sx={theme => ({
                    fontSize: "0.8rem",
                    "&.MuiInputLabel-shrink": {
                        transform: "translate(7px, -10px) scale(1)",
                        px: 2,
                        backgroundColor: theme.palette.deepWalkBlue.dark,
                        color: theme.palette.text.secondary,
                        borderRadius: theme.shape.borderRadius,
                        fontWeight: theme.typography.fontWeightBold,
                        fontSize: "0.9rem",
                    },
                })}>
                {label}
            </InputLabel>
            <Select
                open={open}
                onOpen={onOpen}
                onClose={onClose}
                sx={sx}
                label={label}
                labelId={`${label}-select-label`}
                id={`${label}-select`}
                multiple
                value={selectedOptions?.map(el => el[dataKey] as string) ?? []}
                onChange={(event: SelectChangeEvent<string[]>) => {
                    const selectedValues = Array.isArray(event.target.value)
                        ? [...event.target.value]
                        : [event.target.value];
                    const selectedOptions = options.filter(el => selectedValues.includes(el[dataKey] as string));
                    setSelectedOptions(selectedOptions);
                }}
                renderValue={selected => selected.join(", ")}>
                {options && options.length > 0 ? (
                    options?.map(option => (
                        <MenuItem key={option[dataKey] as string} value={option[dataKey] as string}>
                            <Checkbox checked={selectedOptions?.includes(option)} />
                            {option[dataKey] as string}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem disabled>No options available</MenuItem>
                )}
            </Select>
        </FormControl>
    );
};
