import {
    Avatar,
    AvatarGroup,
    FormControl,
    Grid,
    MenuItem,
    Select,
    type SelectChangeEvent,
    Stack,
    Tooltip,
    Typography,
    lighten,
} from "@mui/material";
import {useProject} from "contexts/ProjectContext";
import React from "react";
import type {CleanMapScan, CleanScan, Project} from "react_ct/types";
import {GridItem} from "../Home";
import {colors} from "react_ct/theme";
import {BarChart, PieChart} from "@mui/x-charts";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs, {type Dayjs} from "dayjs";
import {cmToMiles} from "helpers/utils";
import {useQuery} from "@tanstack/react-query";
import {scanKeys} from "queries/queries";
import {getCleanProjectScans} from "react_ct/requests";

const avatarColors = [
    colors.yellow,
    colors.lightBlue,
    colors.orange,
    colors.purple,
    colors.deepWalkBlue,
    colors.darkGray,
    colors.green,
];

type DateInterval = "day" | "week" | "month";

export default function CollectionDashboard({project}: {project: Project}): React.ReactElement {
    const {projectUserList} = useProject();
    const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
    const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
    const [scansDateInterval, setScansDateInterval] = React.useState<DateInterval>("day");

    const {data: projectScans, error: projectScansError} = useQuery({
        queryKey: scanKeys.scans(project.id),
        queryFn: async () => {
            const data = await getCleanProjectScans(project.id);
            const updatedData: CleanMapScan[] = data.map((scan: CleanScan) => {
                const coords = scan.robustGpsCoordinates ?? scan.gpsCoordinates;
                const latlng = coords?.replace("POINT(", "").replace(")", "").split(" ");
                const lat = parseFloat(latlng?.[0] ?? "");
                const lng = parseFloat(latlng?.[1] ?? "");
                return {
                    ...scan,
                    lat,
                    lng,
                    scannerId: scan.userId,
                    gpsMultiline: scan.gpsMultiline?.map(coord => [coord[1], coord[0]]) ?? null,
                    adjustedGpsMultiline: scan.adjustedGpsMultiline?.map(coord => [coord[1], coord[0]]) ?? null,
                    createdAt: scan.createdAt.getTime(),
                    updatedAt: scan.updatedAt.getTime(),
                    confirmed: scan.scanLengthConfirmed,
                };
            });
            return updatedData;
        },
        enabled: !!project,
    });

    const filteredScans = React.useMemo(() => {
        if (!projectScans) return undefined;
        const filtered = projectScans
            .filter(scan => scan.stage !== "archive")
            .filter(scan => {
                if (startDate !== null && endDate !== null)
                    return dayjs(scan.createdAt).isAfter(startDate) && dayjs(scan.createdAt).isBefore(endDate);
                else if (startDate !== null && endDate === null) return dayjs(scan.createdAt).isAfter(startDate);
                else if (startDate === null && endDate !== null) return dayjs(scan.createdAt).isBefore(endDate);
                else return true;
            });
        return filtered;
    }, [projectScans, startDate, endDate]);

    const totalMileage = React.useMemo(() => {
        if (!filteredScans) return 0;
        return cmToMiles(
            filteredScans.reduce((total, current) => {
                return total + current.scanLength;
            }, 0),
        );
    }, [filteredScans]);

    const milesPerUser = React.useMemo(() => {
        if (!filteredScans || !projectUserList) return undefined;
        return projectUserList.map(user => {
            const userScans = filteredScans.filter(scan => scan.scannerId === user.userId);
            return {
                userId: user.userId,
                scans: cmToMiles(userScans.reduce((total, current) => total + current.scanLength, 0)),
            };
        });
    }, [filteredScans, projectUserList]);

    const milesPerStage: Partial<Record<string, number>> | undefined = React.useMemo(() => {
        if (!filteredScans) return undefined;
        const initial: Partial<Record<string, number>> = {};
        return filteredScans.reduce((accumulated, current) => {
            if (accumulated[current.stage])
                accumulated[current.stage] = (accumulated[current.stage] ?? 0) + cmToMiles(current.scanLength);
            else accumulated[current.stage] = cmToMiles(current.scanLength);
            return accumulated;
        }, initial);
    }, [filteredScans]);

    const milesPerDate = React.useMemo(() => {
        if (!filteredScans) return undefined;
        const initial: Array<{
            date: number;
            scans: number;
        }> = [];
        switch (scansDateInterval) {
            case "day":
                return filteredScans
                    .reduce((accumulated, current) => {
                        const parsedDate = dayjs(current.createdAt);
                        const currentMonth = parsedDate.month();
                        const currentDay = parsedDate.date();
                        const currentYear = parsedDate.year();
                        const dateObject = new Date(currentYear, currentMonth, currentDay);
                        const dateKeyInt = dayjs(dateObject).valueOf();
                        const indexOfObject = accumulated.findIndex(dateScans => dateScans.date === dateKeyInt);
                        if (indexOfObject >= 0)
                            accumulated[indexOfObject].scans =
                                accumulated[indexOfObject].scans + cmToMiles(current.scanLength);
                        else
                            accumulated.push({
                                date: dateKeyInt,
                                scans: cmToMiles(current.scanLength),
                            });
                        return accumulated;
                    }, initial)
                    .sort((a, b) => a.date - b.date);
            case "month":
                return filteredScans
                    .reduce((accumulated, current) => {
                        const parsedDate = dayjs(current.createdAt);
                        const currentMonth = parsedDate.month();
                        const currentYear = parsedDate.year();
                        const dateObject = new Date(currentYear, currentMonth, 1);
                        const dateKeyInt = dayjs(dateObject).valueOf();
                        const indexOfObject = accumulated.findIndex(dateScans => dateScans.date === dateKeyInt);
                        if (indexOfObject >= 0)
                            accumulated[indexOfObject].scans =
                                accumulated[indexOfObject].scans + cmToMiles(current.scanLength);
                        else
                            accumulated.push({
                                date: dateKeyInt,
                                scans: cmToMiles(cmToMiles(current.scanLength)),
                            });
                        return accumulated;
                    }, initial)
                    .sort((a, b) => a.date - b.date);
            default:
                return undefined;
        }
    }, [scansDateInterval, filteredScans]);

    const formatDateKey = (dateKey: number): string => {
        if (scansDateInterval === "day") {
            return dayjs(dateKey).format("MM/DD/YYYY");
        } else {
            return dayjs(dateKey).format("MMM YYYY");
        }
    };

    const formatMiles = (miles: number | null): string => {
        return `${new Intl.NumberFormat().format(Math.round((miles ?? 0) * 10) / 10)} miles`;
    };

    const handleDateIntervalChange = (event: SelectChangeEvent): void => {
        setScansDateInterval(event.target.value as DateInterval);
    };

    if (projectScansError) return <Typography textAlign="center">No scans are available for this project</Typography>;
    else
        return (
            <Stack gap={6} px={6}>
                <FormControl size="small" sx={{display: "flex", flexFlow: "row nowrap", alignItems: "center", gap: 2}}>
                    <DatePicker
                        label="Start Date"
                        value={startDate}
                        onChange={newDate => setStartDate(newDate)}
                        slotProps={{field: {clearable: true, onClear: () => setStartDate(null)}}}
                    />
                    <DatePicker
                        label="End Date"
                        value={endDate}
                        onChange={newDate => setEndDate(newDate)}
                        slotProps={{field: {clearable: true, onClear: () => setEndDate(null)}}}
                    />
                </FormControl>
                <Grid container rowSpacing={{xs: 2}} columnSpacing={{xs: 2}}>
                    <GridItem sm={6} lg={4} title="Scanners">
                        <Stack direction="row" gap={2} sx={{flexWrap: "wrap"}}>
                            <AvatarGroup>
                                {projectUserList?.map((user, index) => (
                                    <Tooltip key={user.id} placement="top" title={user.userEmail}>
                                        <Avatar sx={{bgcolor: avatarColors[index]}}>
                                            {user.userEmail[user.userEmail.length - 1].toUpperCase() ?? "?"}
                                        </Avatar>
                                    </Tooltip>
                                ))}
                            </AvatarGroup>
                            <Typography variant="h4">{projectUserList?.length} scanners</Typography>
                        </Stack>
                    </GridItem>
                    <GridItem sm={6} lg={2} title="Total miles scanned">
                        <Tooltip title={`${new Intl.NumberFormat().format(totalMileage)} miles`}>
                            <Typography variant="h4">{totalMileage.toFixed(1)} miles</Typography>
                        </Tooltip>
                    </GridItem>
                    <GridItem sm lg={6} title="Mileage breakdown">
                        {milesPerStage ? (
                            <PieChart
                                width={400}
                                height={200}
                                series={[
                                    {
                                        innerRadius: 50,
                                        data: Object.keys(milesPerStage).map((stage, index) => ({
                                            id: stage,
                                            value: Object.values(milesPerStage)[index] ?? 0,
                                            label: stage,
                                        })),
                                    },
                                ]}
                            />
                        ) : (
                            <></>
                        )}
                    </GridItem>
                    {projectUserList && milesPerUser && (
                        <GridItem sm lg={6} title="Miles collected per scanner">
                            <BarChart
                                width={600}
                                height={400}
                                layout="horizontal"
                                grid={{vertical: true}}
                                xAxis={[{label: "Scans collected"}]}
                                yAxis={[
                                    {
                                        scaleType: "band",
                                        dataKey: "userId",
                                        colorMap: {
                                            type: "piecewise",
                                            thresholds: milesPerUser.map(user => user.userId).slice(1),
                                            colors: avatarColors,
                                        },
                                        valueFormatter: userId =>
                                            projectUserList.find(u => u.userId === userId)?.userEmail ?? userId,
                                    },
                                ]}
                                dataset={milesPerUser}
                                series={[{dataKey: "scans", label: "Scans collected", valueFormatter: formatMiles}]}
                                slotProps={{legend: {hidden: true}}}
                            />
                        </GridItem>
                    )}
                    {milesPerDate && (
                        <GridItem sm lg={6}>
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Typography>Miles collected per</Typography>
                                <FormControl size="small">
                                    <Select value={scansDateInterval} onChange={handleDateIntervalChange}>
                                        <MenuItem value="day">Day</MenuItem>
                                        <MenuItem value="month">Month</MenuItem>
                                    </Select>
                                </FormControl>
                            </Stack>
                            <BarChart
                                width={600}
                                height={400}
                                dataset={milesPerDate.map(dateScans => ({...dateScans, scans: dateScans.scans}))}
                                series={[
                                    {
                                        dataKey: "scans",
                                        valueFormatter: formatMiles,
                                        color: lighten(colors.deepWalkBlue, 0.2),
                                    },
                                ]}
                                xAxis={[{scaleType: "band", dataKey: "date", valueFormatter: formatDateKey}]}
                            />
                        </GridItem>
                    )}
                </Grid>
            </Stack>
        );
}
