import {useState} from "react";

export interface ArrayActions<T> {
    push: (newValue: T) => void;
    replace: (index: number, newValue: T) => void;
    remove: (index: number) => void;
    overwrite: (newArray: T[]) => void;
}

export function useArray<T>(array: T[]): [T[], ArrayActions<T>] {
    const [stateArray, setArray] = useState<T[]>(array);

    /**
     * Pushes a new value into the array
     * @param newValue The new value to push into the array
     */
    const push = (newValue: T): void => {
        setArray(stateArray => (!stateArray ? [newValue] : stateArray.concat(newValue)));
    };

    /**
     * Replace an item in the array at a given index
     * @param index The index of the value to replace
     * @param newValue The new value to insert into the array
     */
    const replace = (index: number, newValue: T): void => {
        if (stateArray) {
            const newArray = [...stateArray];
            newArray[index] = newValue;
            setArray(newArray);
        }
    };

    /**
     * Remove an item from the array at a given index
     * @param {number} index The index of the value to remove
     */
    const remove = (index: number): void => {
        if (stateArray) {
            const newArray = [...stateArray];
            newArray.splice(index, 1);
            setArray(newArray);
        }
    };

    /**
     * Overwrite the existing array with a new array
     * @param newArray The new array to overwrite the existing array with
     */
    const overwrite = (newArray: T[]): void => {
        setArray(newArray);
    };

    return [stateArray, {push, replace, remove, overwrite}];
}
