import React from "react";
import {Download} from "@mui/icons-material";
import {Box, Button, Checkbox, FormControlLabel, FormGroup, IconButton, Menu, Typography} from "@mui/material";
import type {DownloadURL, GeoJSONDataType} from "./helpers";
import {useArray} from "hooks/useArray";

const DownloadJSONs: React.FunctionComponent<{
    downloadUrls: DownloadURL;
}> = props => {
    const {downloadUrls} = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [toDownload, toDownloadHandlers] = useArray<GeoJSONDataType>([]);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const keyToLabel = (key: GeoJSONDataType): string => {
        switch (key) {
            case "geojson":
                return "Scan Features";
            case "indvData":
                return "Manual Tags";
            case "obsData":
                return "Obstructions";
            case "communityData":
                return "Demographics";
            default:
                return key;
        }
    };

    return (
        <Box>
            <IconButton
                aria-haspopup="true"
                onClick={handleClick}
                sx={theme => ({
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: theme.shape.borderRadius,
                    boxShadow: theme.shadows[4],
                    "&:hover": {
                        backgroundColor: theme.palette.grey[100],
                    },
                })}>
                <Download fontSize="large" />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                onClose={handleClose}
                open={open}
                sx={theme => ({
                    "& .MuiMenu-paper": {
                        px: 2,
                        py: 1,
                    },
                })}>
                <Typography fontWeight={700} mb={1}>
                    Files for Download
                </Typography>
                <FormGroup>
                    {Object.entries(downloadUrls).map(urlObj => {
                        const key: GeoJSONDataType = urlObj[0] as GeoJSONDataType;
                        return (
                            <FormControlLabel
                                key={urlObj[0]}
                                value={urlObj[0]}
                                onChange={() => {
                                    if (toDownload.includes(key)) {
                                        const keyIndex = toDownload.indexOf(key);
                                        toDownloadHandlers.remove(keyIndex);
                                    } else {
                                        toDownloadHandlers.push(key);
                                    }
                                }}
                                control={
                                    <Checkbox
                                        sx={theme => ({
                                            "&.MuiCheckbox-colorPrimary": {
                                                color: theme.palette.grey[300],
                                            },

                                            "&.Mui-checked": {
                                                color: theme.palette.primary.main,
                                            },
                                        })}
                                    />
                                }
                                label={keyToLabel(urlObj[0] as GeoJSONDataType)}
                            />
                        );
                    })}
                    <Button
                        variant="contained"
                        sx={{mt: 1}}
                        disabled={toDownload.length === 0}
                        onClick={() => {
                            for (const link of toDownload) {
                                const download = downloadUrls[link];
                                if (download.url && download.fileName) {
                                    const a = document.createElement("a");
                                    a.href = download.url;
                                    a.download = download.fileName;
                                    document.body.appendChild(a);
                                    a.click();
                                    document.body.removeChild(a);
                                }
                            }
                        }}>
                        Download
                    </Button>
                </FormGroup>
            </Menu>
        </Box>
    );
};

export default DownloadJSONs;
