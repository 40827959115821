import React from "react";
import {Alert, type AlertColor, Box, Fab, FormControlLabel, FormGroup, Paper, Snackbar, Switch} from "@mui/material";
import {useProject} from "contexts/ProjectContext";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {apiRequest} from "react_ct/requests";
import {type MapScan} from "react_ct/types";
import DWMap from "components/Map/DWMap";
import {createGeoJSONFeatureCollection} from "components/Map/helpers";
import {colors} from "react_ct/theme";
import LoadingScreen from "components/LoadingScreen";
import LoadingError from "components/LoadingError";
import {CheckCircleOutline, ErrorOutline, HourglassTop, Save} from "@mui/icons-material";
import {type AxiosResponse} from "axios";
import {isEqual} from "lodash";
import ScanPreview from "./components/Adjust/ScanPreview";
import {scanKeys} from "queries/queries";
import {useBlocker} from "react-router-dom";
import BeforeUnloadDialog from "components/BeforeUnloadDialog";

export default function AdjustPage(): React.ReactElement {
    const queryClient = useQueryClient();
    const {currentProject, projectsError, projectUserListError, projectScans, projectScansError} = useProject();

    const updatedScanIds = React.useRef<number[]>([]);

    const filteredScans = React.useMemo(() => {
        return projectScans?.filter(
            scan => scan.stage.toLowerCase() !== "archived" && scan.stage.toLowerCase() !== "rescan",
        );
    }, [projectScans]);

    const [updatedCollection, setUpdatedCollection] = React.useState<GeoJSON.FeatureCollection>({
        type: "FeatureCollection",
        features: [],
    });

    const [lastSavedCollection, setLastSavedCollection] = React.useState<GeoJSON.FeatureCollection>(updatedCollection);

    const adjustedCollection: GeoJSON.FeatureCollection = React.useMemo(() => {
        return {
            type: "FeatureCollection",
            features: updatedCollection.features
                .filter(feature => (feature.geometry as GeoJSON.LineString).coordinates)
                .map(feature => {
                    return {
                        ...feature,
                        properties: {
                            ...feature.properties,
                            adjustedGpsMultiline: (feature.geometry as GeoJSON.LineString).coordinates,
                        },
                    };
                }),
        };
    }, [updatedCollection]);

    const scanFeatures = React.useMemo(() => {
        if (!filteredScans) return;
        return createGeoJSONFeatureCollection(filteredScans, () => colors.blue);
    }, [filteredScans]);

    const blocker = useBlocker(({currentLocation, nextLocation}) => {
        return currentLocation.pathname !== nextLocation.pathname && hasUnsavedChanges;
    });

    const updateOneMultiline = async (scanProperties: MapScan): Promise<AxiosResponse> => {
        if (!scanProperties.adjustedGpsMultiline) throw new Error("Scan does not have adjusted multilines");
        const flippedAdjustedProperties = {
            ...scanProperties,
            gpsMultiline: scanProperties.gpsMultiline?.map(coord => [coord[1], coord[0]]),
            adjustedGpsMultiline: scanProperties.adjustedGpsMultiline.map(coord => [coord[1], coord[0]]),
        };

        return await apiRequest({
            method: "PUT",
            path: "scan",
            params: {scanId: String(scanProperties.id)},
            data: {
                adjustedGpsMultiline: flippedAdjustedProperties.adjustedGpsMultiline,
            },
        });
    };

    const updateMultilineMutation = useMutation({
        mutationFn: async (newCollection: GeoJSON.FeatureCollection) => {
            if (!newCollection.features) throw new Error("There is no collection or features to update");
            const scansToUpdate = newCollection.features.filter(
                feature =>
                    !isEqual(feature.properties?.gpsMultiline, feature.properties?.adjustedGpsMultiline) &&
                    updatedScanIds.current.includes(feature.properties?.id),
            );
            if (!scansToUpdate.length) throw new Error("There is no collection or features to update");
            const promisesToExecute = scansToUpdate.map(feature => {
                if (!feature.properties) throw new Error("Feature does not contain any properties");
                return updateOneMultiline(feature.properties as MapScan);
            });
            const res = await Promise.all(promisesToExecute);
            return res;
        },
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: scanKeys.scans(currentProject?.id),
            });
            if (blocker.state === "blocked" && blocker.proceed) blocker.proceed();
        },
    });

    const isError = !!projectUserListError || !!projectScansError || !!projectsError;
    const errorMessage: string | undefined = isError
        ? (projectUserListError ?? projectScansError ?? projectsError)
        : undefined;

    const hasUnsavedChanges = React.useMemo(() => {
        if (!adjustedCollection || !lastSavedCollection) return false;
        return !isEqual(adjustedCollection, lastSavedCollection);
    }, [lastSavedCollection, adjustedCollection]);

    const handleSave = (): void => {
        updateMultilineMutation.mutate(adjustedCollection);
    };

    React.useEffect(() => {
        if (scanFeatures?.features) {
            const adjustedGeometry = {
                ...scanFeatures,
                features: scanFeatures.features.map(feature => {
                    return {
                        ...feature,
                        geometry: {
                            ...feature.geometry,
                            coordinates: feature.properties?.adjustedGpsMultiline?.length
                                ? feature.properties?.adjustedGpsMultiline
                                : feature.properties?.gpsMultiline,
                        },
                    };
                }),
            };
            if (updatedCollection.features.length === 0) setUpdatedCollection(adjustedGeometry);
            if (lastSavedCollection.features.length === 0) setLastSavedCollection(adjustedGeometry);
        }
    }, [scanFeatures]);

    const [showOriginalScans, setShowOriginalScans] = React.useState(true);
    const handleToggleSwitch = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setShowOriginalScans(event.target.checked);
    };

    const [selectedScan, setSelectedScan] = React.useState<GeoJSON.Feature<GeoJSON.LineString, MapScan>>();
    const [snackbarMessage, setSnackbarMessage] = React.useState<{severity: AlertColor; message: string}>();

    React.useEffect(() => {
        if (updateMultilineMutation.isPending) {
            setSnackbarMessage({
                severity: "info",
                message: "Saving changes...",
            });
        } else if (updateMultilineMutation.isSuccess) {
            setSnackbarMessage({
                severity: "success",
                message: "Changes saved successfully!",
            });
        } else if (updateMultilineMutation.isError) {
            console.error(updateMultilineMutation.error);
            setSnackbarMessage({
                severity: "error",
                message: `Error saving: ${updateMultilineMutation.error.message}`,
            });
            if (blocker.state === "blocked" && blocker.reset) blocker.reset();
        }
    }, [
        updateMultilineMutation.isPending,
        updateMultilineMutation.isSuccess,
        updateMultilineMutation.isError,
        updateMultilineMutation.error?.message,
    ]);

    return (
        <Box id="page-container" width="100%" height="100vh" position="relative">
            {!scanFeatures?.features.length || !updatedCollection.features.length ? (
                <LoadingScreen />
            ) : isError ? (
                <LoadingError {...{errorMessage}} />
            ) : (
                <>
                    <BeforeUnloadDialog
                        {...{blocker}}
                        onSave={() => {
                            updateMultilineMutation.mutate(adjustedCollection);
                        }}
                        onLeave={() => {
                            if (blocker.reset) blocker.proceed();
                        }}
                    />
                    <Snackbar
                        open={!!snackbarMessage}
                        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                        onClose={() => setSnackbarMessage(undefined)}>
                        <Alert
                            severity={snackbarMessage?.severity}
                            icon={
                                snackbarMessage?.severity === "success" ? (
                                    <CheckCircleOutline />
                                ) : snackbarMessage?.severity === "info" ? (
                                    <HourglassTop />
                                ) : (
                                    <ErrorOutline />
                                )
                            }>
                            {snackbarMessage?.message}
                        </Alert>
                    </Snackbar>
                    <Paper
                        elevation={3}
                        sx={{
                            position: "absolute",
                            top: 8,
                            left: 24,
                            zIndex: 20,
                            borderRadius: theme => theme.shape.borderRadius,
                            px: 2,
                        }}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={showOriginalScans} onChange={handleToggleSwitch} />}
                                label="Show original scan lines"
                            />
                        </FormGroup>
                    </Paper>
                    <DWMap
                        data={showOriginalScans ? scanFeatures : {type: "FeatureCollection", features: []}}
                        defaultSatelliteView="satellite"
                        drawFeatures={adjustedCollection}
                        setDrawFeatures={setUpdatedCollection}
                        dataId="id"
                        selectedFeature={undefined}
                        onDrawnFeatureClick={(feature: GeoJSON.Feature | undefined) => {
                            setSelectedScan(feature as GeoJSON.Feature<GeoJSON.LineString, MapScan>);
                        }}
                        updatedScanIds={updatedScanIds}
                    />
                    <Fab
                        color="primary"
                        aria-label="save"
                        sx={{position: "absolute", bottom: 32, right: 32, width: "auto", height: "auto", p: 2}}
                        disabled={!hasUnsavedChanges}
                        onClick={handleSave}>
                        <Save sx={{fontSize: "4rem"}} />
                    </Fab>
                    {selectedScan && (
                        <ScanPreview
                            scan={{
                                ...selectedScan.properties,
                            }}
                        />
                    )}
                </>
            )}
        </Box>
    );
}
