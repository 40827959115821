import React from "react";
import {Box, Button, Chip, Divider, Drawer, Grid, IconButton, Typography} from "@mui/material";
import {CheckCircleOutline, Close, ErrorOutline, HourglassBottom, Pending} from "@mui/icons-material";
import {getScanCoordsString} from "../../Map";
import ModalImage from "react-modal-image";
import {makeStyles} from "@mui/styles";
import {colors} from "react_ct/theme";
import {type CleanMapScan} from "react_ct/types";

const useStyles = makeStyles({
    drawer: {
        width: 300,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 300,
        height: "100%",
        overflowY: "auto",
        backgroundColor: colors.white,
        color: colors.darkBlue,
    },
});

export default function ScanDetailsDrawer({
    selectedScan,
    rasterUrl,
    openDrawer,
    setOpenDrawer,
    setOpenDialog,
    displayMiles,
    scanUpdating,
}: {
    selectedScan: CleanMapScan | undefined;
    rasterUrl: string | undefined;
    openDrawer: boolean;
    setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
    displayMiles: (cm: number) => string;
    scanUpdating: boolean;
}): React.ReactElement {
    // hooks
    const classes = useStyles();

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={openDrawer}
            classes={{
                paper: classes.drawerPaper,
            }}>
            {Boolean(selectedScan) && (
                <Box padding={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box marginTop={1}>
                                <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                                    <IconButton onClick={() => setOpenDrawer(false)} sx={{padding: 0}}>
                                        <Close />
                                    </IconButton>
                                </Box>
                                <Box sx={{boxSizing: "border-box", padding: "5px"}}>
                                    <Typography
                                        component="h3"
                                        variant="h3"
                                        sx={theme => ({
                                            fontSize: "1.2rem",
                                            color: theme.palette.text.primary,
                                            mb: 1,
                                            fontWeight: "bold",
                                        })}>
                                        Scan ID
                                    </Typography>
                                    {selectedScan?.id}
                                </Box>
                                <Divider />
                                <Box sx={{boxSizing: "border-box", padding: "5px"}}>
                                    <Typography
                                        component="h3"
                                        variant="h3"
                                        sx={theme => ({
                                            fontSize: "1.2rem",
                                            color: theme.palette.text.primary,
                                            mb: 1,
                                            fontWeight: "bold",
                                        })}>
                                        Folder name
                                    </Typography>
                                    {selectedScan?.folderName}
                                </Box>
                                <Divider />
                                <Box
                                    sx={{
                                        boxSizing: "border-box",
                                        padding: "5px",
                                    }}>
                                    <Typography
                                        component="h3"
                                        variant="h3"
                                        sx={theme => ({
                                            fontSize: "1.2rem",
                                            color: theme.palette.text.primary,
                                            mb: 1,
                                            fontWeight: "bold",
                                        })}>
                                        Stage
                                    </Typography>
                                    <Chip
                                        icon={
                                            selectedScan?.stage === "Rescan" ? (
                                                <ErrorOutline />
                                            ) : selectedScan?.stage === "Completed" ? (
                                                <CheckCircleOutline />
                                            ) : selectedScan?.stage === "Processing" ? (
                                                <HourglassBottom />
                                            ) : selectedScan?.stage === "Uploaded" ? (
                                                <Pending />
                                            ) : undefined
                                        }
                                        color={
                                            selectedScan?.stage === "Rescan"
                                                ? "error"
                                                : selectedScan?.stage === "Uploaded"
                                                ? "info"
                                                : selectedScan?.stage === "Completed"
                                                ? "success"
                                                : undefined
                                        }
                                        label={selectedScan?.stage}
                                        variant="outlined"
                                    />
                                </Box>
                                <Divider />
                                <Box
                                    sx={{
                                        boxSizing: "border-box",
                                        padding: "5px",
                                    }}>
                                    <Typography
                                        variant="h3"
                                        component="h3"
                                        marginBottom={1}
                                        sx={{
                                            fontSize: "1.2rem",
                                            mb: 1,
                                            fontWeight: "bold",
                                        }}>
                                        Coordinates
                                    </Typography>
                                    {getScanCoordsString(selectedScan)}
                                </Box>
                                <Divider />
                                <Box
                                    sx={{
                                        boxSizing: "border-box",
                                        padding: "5px",
                                    }}>
                                    <Typography
                                        variant="h3"
                                        component="h3"
                                        sx={theme => ({
                                            fontSize: "1.2rem",
                                            color: theme.palette.text.primary,
                                            mb: 1,
                                            fontWeight: "bold",
                                        })}>
                                        Created at
                                    </Typography>{" "}
                                    {new Date(selectedScan?.createdAt ?? 0).toLocaleString()}
                                </Box>
                                <Divider />
                                <Box
                                    sx={{
                                        boxSizing: "border-box",
                                        padding: "5px",
                                    }}>
                                    <Typography
                                        component="h3"
                                        variant="h3"
                                        sx={theme => ({
                                            fontSize: "1.2rem",
                                            color: theme.palette.text.primary,
                                            mb: 1,
                                            fontWeight: "bold",
                                        })}>
                                        Scan length
                                    </Typography>
                                    {selectedScan?.scanLength ? displayMiles(selectedScan.scanLength) : "Unknown"}
                                </Box>
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <Button
                                    variant="contained"
                                    onClick={() => setOpenDialog(true)}
                                    disabled={scanUpdating}
                                    sx={{borderRadius: 50, fontWeight: theme => theme.typography.fontWeightBold}}>
                                    Archive Scan
                                </Button>
                            </Box>
                        </Grid>
                        {Boolean(rasterUrl) && (
                            <Grid item xs={12} width={"100%"}>
                                <ModalImage
                                    small={rasterUrl!}
                                    large={rasterUrl}
                                    showRotate={true}
                                    alt={selectedScan?.folderName ?? "From S3"}
                                    style={{position: "fixed", zIndex: 10000}}
                                    hideDownload={true}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Box>
            )}
        </Drawer>
    );
}
