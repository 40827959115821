import React from "react";

interface LayoutContextType {
    openNav: boolean;
    setOpenNav: (open: boolean) => void;
}

export const LayoutContext = React.createContext<LayoutContextType>({
    openNav: false,
    setOpenNav: open => {
        // do nothing
    },
});

export const useLayout = (): LayoutContextType => {
    return React.useContext(LayoutContext);
};

export const LayoutProvider: React.FC<{children: JSX.Element}> = ({children}) => {
    const [openNav, setOpenNav] = React.useState(false);

    return <LayoutContext.Provider value={{openNav, setOpenNav}}>{children}</LayoutContext.Provider>;
};
