import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import React from "react";
import {type Blocker} from "react-router-dom";

export default function BeforeUnloadDialog({
    blocker,
    onSave,
    onLeave,
}: {
    blocker: Blocker;
    onSave: () => void;
    onLeave: () => void;
}): React.ReactElement {
    const open = blocker.state === "blocked";

    const onClose = (): void => {
        if (blocker.reset) blocker.reset();
    };

    return (
        <Dialog {...{open, onClose}} aria-labelledby="unsaved-changes-title" aria-describedby="unsaved-changes-desc">
            <DialogTitle id="unsaved-changes-title">Unsaved Changes</DialogTitle>
            <DialogContent>
                <DialogContentText id="unsaved-changes-desc" sx={{color: theme => theme.palette.text.primary}}>
                    You currently have unsaved changes. Would you like to save your changes before navigating away from
                    this page?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onLeave}>Leave page</Button>
                <Button variant="contained" onClick={onSave}>
                    Save changes
                </Button>
            </DialogActions>
        </Dialog>
    );
}
