import React from "react";
import {ArcgisBasemapToggle, ArcgisLayerList, ArcgisMap, ArcgisZoom} from "@arcgis/map-components-react";
import {defineCustomElements} from "@arcgis/map-components/dist/loader";

defineCustomElements(window, {
    resourcesUrl: "https://js.arcgis.com/map-components/4.29/assets",
});

export default function ArcGISMap({mapId}: {mapId: string}): React.ReactElement {
    return (
        <ArcgisMap itemId={mapId}>
            <ArcgisLayerList position="top-right" />
            <ArcgisZoom position="top-left" />
            <ArcgisBasemapToggle position="bottom-left" />
        </ArcgisMap>
    );
}
