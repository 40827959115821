import {Box, lighten} from "@mui/material";
import React from "react";
import {type ViolationSeverity} from "react_ct/types";
import {colors} from "react_ct/theme";

export default function CrossSlopeIcon({
    violation,
    visible = true,
}: {
    violation: ViolationSeverity | "ok";
    visible?: boolean;
}): React.JSX.Element {
    const violationColor = {
        extreme: colors.black,
        severe: colors.red,
        moderate: colors.orange,
        minor: colors.yellow,
        ok: colors.green,
    };

    return (
        <Box
            className="icon"
            width="100%"
            height="100%"
            borderRadius="2px"
            fontWeight={900}
            fontSize="0.6rem"
            display={visible ? "flex" : "none"}
            alignItems="center"
            justifyContent="center"
            border="1px solid white"
            sx={{
                backgroundColor: violationColor[violation],
                color: "white",
                outline: `1px solid ${violationColor[violation as keyof typeof violationColor]}`,
                cursor: "pointer",
                transition: "background-color 0.1s, outline-color 0.1s",
                "&:hover": {
                    backgroundColor: lighten(violationColor[violation as keyof typeof violationColor], 0.4),
                    outlineColor: lighten(violationColor[violation as keyof typeof violationColor], 0.4),
                },
            }}>
            CS
        </Box>
    );
}
