import {Tune} from "@mui/icons-material";
import {Box, Tooltip, IconButton, Collapse, Paper, Stack, Checkbox, Typography, checkboxClasses} from "@mui/material";
import {useLayout} from "contexts/LayoutContext";
import React from "react";

interface FilterMenuProps {
    filters: string[];
    setFilters: React.Dispatch<React.SetStateAction<string[]>>;
    valueLabels: Array<{value: string; label: string; color?: string}>;
}

export default function ImplementFilterMenu({filters, setFilters, valueLabels}: FilterMenuProps): JSX.Element {
    const {openNav} = useLayout();
    const [open, setOpen] = React.useState(true);

    return (
        <Box
            position="absolute"
            top={8}
            left={openNav ? 180 : 12}
            zIndex={5}
            sx={theme => ({
                transition: openNav
                    ? theme.transitions.create("left", {
                          easing: theme.transitions.easing.easeOut,
                          duration: theme.transitions.duration.enteringScreen,
                      })
                    : theme.transitions.create(["left"], {
                          easing: theme.transitions.easing.easeIn,
                          duration: theme.transitions.duration.leavingScreen,
                      }),
            })}>
            <Tooltip title="Filter features" placement="right" arrow>
                <IconButton
                    onClick={() => setOpen(!open)}
                    sx={theme => ({
                        backgroundColor: theme.palette.background.paper,
                        boxShadow: theme.shadows[3],
                        borderRadius: theme.shape.borderRadius / 2,
                    })}>
                    <Tune />
                </IconButton>
            </Tooltip>
            <Collapse in={open}>
                <Paper
                    elevation={3}
                    sx={{
                        mt: 1,
                        p: 2,
                    }}>
                    {valueLabels.map((obj: {value: string; label: string; color?: string}) => (
                        <Stack key={obj.value} direction="row" alignItems="center" spacing={2}>
                            <Checkbox
                                color="info"
                                checked={filters.includes(obj.value)}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    if (event.target.checked) {
                                        setFilters([...filters, obj.value]);
                                    } else {
                                        setFilters(filters.filter(f => f !== obj.value));
                                    }
                                }}
                                sx={{
                                    [`&.${checkboxClasses.checked}`]: {
                                        color: theme => obj.color ?? theme.palette.info.main,
                                    },
                                }}
                            />
                            <Typography
                                sx={{
                                    flexGrow: 1,
                                }}>
                                {obj.label}
                            </Typography>
                        </Stack>
                    ))}
                </Paper>
            </Collapse>
        </Box>
    );
}
