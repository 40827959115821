import {z} from "zod";

import {type UserDB, type LoginResponseType, loginResponseSchema, userSchema} from "react_ct/types";
import {apiRequest} from ".";

export const loginRequest = async (username: string, password: string): Promise<LoginResponseType> => {
    const res = await apiRequest({
        path: "auth/login",
        method: "POST",
        data: {email: username, password},
    });

    const parsedResponse = loginResponseSchema.safeParse(res?.data);

    if (!parsedResponse.success) {
        // throw new Error("Invalid login response");
        console.warn("Zod error: ", parsedResponse.error);
        return res.data as LoginResponseType; // return not-type-safe data so that the app doesn't crash
    }

    return parsedResponse.data;
};

export const spoofedLoginRequest = async (userId: number): Promise<LoginResponseType> => {
    const res = await apiRequest({
        path: `auth/spoof-login?userId=${userId}`,
        adminAuth: true,
        method: "POST",
    });

    const parsedResponse = loginResponseSchema.safeParse(res?.data);

    if (!parsedResponse.success) {
        // throw new Error("Invalid login response");
        console.warn("Zod error: ", parsedResponse.error);
        return res.data as LoginResponseType; // return not-type-safe data so that the app doesn't crash
    }

    return parsedResponse.data;
};

export const apiGetAllUsers = async (): Promise<UserDB[]> => {
    const {data} = await apiRequest({path: `user`});
    const parsedResponse = z.array(userSchema).safeParse(data);
    if (!parsedResponse.success) {
        console.warn("Zod error: ", parsedResponse.error);
        // throw new Error("Invalid response");
        return data as UserDB[]; // return not-type-safe data so that the app doesn't crash
    }
    return parsedResponse.data;
};

export const apiGetScanners = async (): Promise<UserDB[]> => {
    const {data} = await apiRequest({path: `user/role/scanner`});
    const parsedResponse = z.array(userSchema).safeParse(data);
    if (!parsedResponse.success) {
        console.warn("Zod error: ", parsedResponse.error);
        // throw new Error("Invalid response");
        return data as UserDB[]; // return not-type-safe data so that the app doesn't crash
    }
    return parsedResponse.data;
};

export const updateSubRole = async (userId: number, subRole: string): Promise<void> => {
    await apiRequest({method: "PUT", path: `user/${userId}/subrole`, data: {subRole}});
};
