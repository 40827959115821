import React from "react";
import {ArcGISIdentityManager} from "@esri/arcgis-rest-request";

export default function Oauth(): React.ReactElement {
    React.useEffect(() => {
        const completeOAuth = async (): Promise<void> => {
            const hash = location.hash.substring(1); // Remove the '#' at the beginning
            const params = new URLSearchParams(hash);
            const token = params.get("access_token");

            if (token) {
                try {
                    await ArcGISIdentityManager.completeOAuth2({
                        clientId: "P8vpoXNEHn3PzO7M",
                        redirectUri: `${window.location.protocol}//${window.location.hostname}${
                            window.location.port ? ":" + window.location.port : ""
                        }/oauth`,
                        pkce: false,
                        accessToken: token,
                    });
                } catch (error) {
                    console.error("OAuth completion error:", error);
                }
            }
        };

        void completeOAuth();
    }, []);

    return <>Auth</>;
}
