import {colors} from "react_ct/theme";
import styled from "styled-components";

export const MainPanel = styled.div`
    display: flex;
    flex-direction: row;
    height: 100vh;
`;
export const FrontContentWrapper = styled.div`
    flex-grow: 1;
    background-color: ${colors.offWhite}
    display: flex;
    flex-direction: column;
`;
