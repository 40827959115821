import React from "react";
import {HtmlTooltip} from "./HtmlTooltip";
import Stack from "@mui/material/Stack";
import {PriorityHigh} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import {Triangle} from "assets/svg/Triangle";
import type {EvalRegion, ManualTag} from "react_ct/types";
import {useProject} from "contexts/ProjectContext";
import {Box} from "@mui/material";

export function ManualTagIcon({
    manualTag,
    iconSize = 24,
}: {
    manualTag: ManualTag | undefined;
    iconSize?: number;
}): React.ReactElement {
    const backgroundFillColor = manualTag?.color ?? "#000000";
    const iconHalf = iconSize / 2;

    return (
        <svg width={iconSize} height={iconSize}>
            <Triangle
                points={`0 ${iconSize}, ${iconSize} ${iconSize}, ${iconHalf} 0`}
                stroke="white"
                fill={backgroundFillColor}
            />
            <text x={6} y={18} fill="#ffffffee" fontSize={9} fontWeight="bold">
                {manualTag?.name.slice(0, 2).toUpperCase()}
            </text>
        </svg>
    );
}

function ManualTagItem({
    index,
    manualTag,
    point,
    areDisplacementsVisible,
    imgRatio,
    imgScale,
    selectedCanvasItemId,
    setSelectedCanvasItemId,
}: {
    index: number;
    manualTag: ManualTag | undefined;
    point: EvalRegion;
    areDisplacementsVisible: string[];
    imgRatio: number;
    imgScale: number;
    selectedCanvasItemId: string | null;
    setSelectedCanvasItemId: React.Dispatch<React.SetStateAction<string | null>>;
}): React.ReactElement {
    const itemId = `vd-${index}`;

    const [openTooltip, setOpenTooltip] = React.useState<boolean>(false);

    const onOpen = (): void => {
        setOpenTooltip(true);
    };

    const onClose = (): void => {
        setOpenTooltip(false);
    };

    const onClick = (): void => {
        setSelectedCanvasItemId(itemId);
    };

    React.useEffect(() => {
        if (openTooltip && selectedCanvasItemId !== itemId) onClose();
    }, [selectedCanvasItemId]);

    const ICON_SIZE = 24;
    const ICON_HALF = ICON_SIZE / 2;
    const transformRatio = 1 / imgScale > imgRatio + 0.3 ? imgRatio + 0.3 : 1 / imgScale;

    return (
        <HtmlTooltip
            id={itemId}
            open={openTooltip}
            {...{onOpen, onClose, onClick}}
            disableHoverListener={selectedCanvasItemId === itemId}
            title={
                <>
                    <Stack direction="row" alignItems="center">
                        <PriorityHigh />
                        <Typography>{point.label}</Typography>
                    </Stack>
                </>
            }
            sx={{
                display:
                    !areDisplacementsVisible.includes("Deterioration") && point.label === "Deteriorated"
                        ? "none"
                        : !areDisplacementsVisible.includes(point.label)
                          ? "none"
                          : "block",
            }}>
            <Box
                width={`${ICON_SIZE}px`}
                height={`${ICON_SIZE}px`}
                sx={{
                    transform: `scale(${transformRatio})`,
                    top: point.points[0][1] * imgRatio - ICON_HALF,
                    left: point.points[0][0] * imgRatio - ICON_HALF,
                    position: "absolute",
                    display:
                        !areDisplacementsVisible.includes("Deterioration") && point.label === "Deteriorated"
                            ? "none"
                            : !areDisplacementsVisible.includes(point.label)
                              ? "none"
                              : "block",
                    zIndex: 10,
                }}>
                <ManualTagIcon {...{manualTag}} />
            </Box>
        </HtmlTooltip>
    );
}

export default function ManualTagOverlay({
    pointData,
    areDisplacementsVisible,
    imgRatio,
    imgScale,
    selectedCanvasItemId,
    setSelectedCanvasItemId,
}: {
    pointData: EvalRegion[];
    areDisplacementsVisible: string[];
    imgRatio: number;
    imgScale: number;
    selectedCanvasItemId: string | null;
    setSelectedCanvasItemId: React.Dispatch<React.SetStateAction<string | null>>;
}): React.ReactElement {
    const {projectManualTags} = useProject();
    return (
        <>
            {pointData.map((point, index) => {
                const correspondingTag = projectManualTags?.find(tag => tag.name === point.label);
                return (
                    <ManualTagItem
                        key={index}
                        manualTag={correspondingTag}
                        {...{
                            index,
                            point,
                            areDisplacementsVisible,
                            imgRatio,
                            imgScale,
                            selectedCanvasItemId,
                            setSelectedCanvasItemId,
                        }}
                    />
                );
            })}
        </>
    );
}
