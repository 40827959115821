import React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import {theme} from "react_ct/theme";

/**
 * Interface for the ConfirmDialog component props
 */
interface ConfirmDialogProps {
    /** True if the dialog is open, false if it is closed */
    open: boolean;
    /** Function that sets the open state of the dialog */
    setOpen: (open: boolean) => void;
    /** The title of the dialog */
    title: string;
    /** The description of the dialog */
    description: string;
    /** Function to be executed on confirmation */
    onConfirm: () => void;
}

/**
 * A custom confirm dialog component that is used to confirm user actions.
 * @returns {React.FC<ConfirmDialogProps>} A custom confirm dialog component
 */
export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({open, setOpen, title, description, onConfirm}) => {
    const handleConfirm = (): void => {
        onConfirm();
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText color={theme.palette.text.primary} id="alert-dialog-description">
                        {description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button onClick={handleConfirm} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
