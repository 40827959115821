import {ZoomIn, ZoomOut, CenterFocusWeak, InsertPhoto, Download, CheckBoxOutlineBlank} from "@mui/icons-material";
import {
    type Theme,
    ButtonGroup,
    IconButton,
    Box,
    Paper,
    Tooltip,
    Stack,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import React, {useState, useRef, useEffect, Fragment, type ReactNode} from "react";
import {
    type ReactZoomPanPinchContentRef,
    TransformWrapper,
    TransformComponent,
    useTransformEffect,
} from "react-zoom-pan-pinch";
import {useTheme} from "styled-components";
import {EvalRegion, ReportType} from "react_ct/types";
import VerticalDisplacementOverlay from "./ZoomCanvas/VerticalDisplacementOverlay";
import FilterMenu from "./ZoomCanvas/ZoomCanvasFilterMenu";
import RegionOverlay from "./ZoomCanvas/PolygonOverlay";
import AggregatedRegionOverlay from "./ZoomCanvas/AggregatedRegionOverlay";
import WarningOverlay from "./ZoomCanvas/WarningOverlay";
import {TableLegend} from "./TableLegend";

function ZoomControls({
    zoomIn,
    zoomOut,
    resetTransform,
    disableZoomIn,
    disableZoomOut,
}: ReactZoomPanPinchContentRef & {disableZoomIn: boolean; disableZoomOut: boolean}): React.ReactElement {
    return (
        <ButtonGroup
            variant="contained"
            orientation="vertical"
            disableElevation
            aria-label="Zoom controls for the raster image frame"
            sx={theme => ({
                zIndex: 10,
                backgroundColor: theme.palette.background.paper,
            })}>
            <Tooltip title="Zoom In" placement="right" arrow>
                <IconButton aria-label="Zoom in" onClick={() => zoomIn()} disabled={disableZoomIn}>
                    <ZoomIn />
                </IconButton>
            </Tooltip>
            <Tooltip title="Zoom Out" placement="right" arrow>
                <IconButton aria-label="Zoom out" onClick={() => zoomOut()} disabled={disableZoomOut}>
                    <ZoomOut />
                </IconButton>
            </Tooltip>
            <Tooltip title="Reset Zoom" placement="right" arrow>
                <IconButton aria-label="Reset zoom" onClick={() => resetTransform()}>
                    <CenterFocusWeak />
                </IconButton>
            </Tooltip>
        </ButtonGroup>
    );
}

const DownloadButtons: React.FC<{
    pdfLink: string | null | undefined;
    scanLink: string;
}> = props => {
    const {pdfLink, scanLink} = props;
    return (
        <ButtonGroup
            variant="contained"
            orientation="vertical"
            disableElevation
            aria-label="Buttons to download GeoJSON data and scan image files"
            sx={theme => ({
                zIndex: 10,
                backgroundColor: theme.palette.background.paper,
            })}>
            <Tooltip title="Download Autoreport PDF" placement="right" arrow>
                <IconButton
                    aria-label="Download Autoreport PDF"
                    component="a"
                    href={pdfLink as string}
                    disabled={pdfLink === undefined}
                    download>
                    <Download />
                </IconButton>
            </Tooltip>
            <Tooltip title="Download Scan Image" placement="right" arrow>
                <IconButton
                    aria-label="Download Scan Image"
                    onClick={() => {
                        window.open(scanLink, "_blank")?.focus();
                    }}>
                    <InsertPhoto />
                </IconButton>
            </Tooltip>
        </ButtonGroup>
    );
};

const ZoomContainer: React.FC<
    ReactZoomPanPinchContentRef & {
        children: ReactNode;
        canvasRef: React.RefObject<HTMLDivElement>;
        imgSrc: string;
        setImgScale: React.Dispatch<React.SetStateAction<number>>;
        setDisableZoomIn: React.Dispatch<React.SetStateAction<boolean>>;
        setDisableZoomOut: React.Dispatch<React.SetStateAction<boolean>>;
    }
> = props => {
    const {children, canvasRef, imgSrc, setTransform, setImgScale, setDisableZoomIn, setDisableZoomOut} = props;

    useEffect(() => {
        const img = new Image();
        img.src = imgSrc;
        img.onload = () => {
            const IMAGE_WIDTH = img.width;
            const IMAGE_HEIGHT = img.height;
            let adjustedWidth = IMAGE_WIDTH;
            let adjustedHeight = IMAGE_HEIGHT;

            if (IMAGE_WIDTH >= IMAGE_HEIGHT) {
                adjustedWidth = 600;
                adjustedHeight = (600 * IMAGE_HEIGHT) / IMAGE_WIDTH;
            } else {
                adjustedHeight = 600;
                adjustedWidth = (600 * IMAGE_WIDTH) / IMAGE_HEIGHT;
            }

            const xPos = canvasRef.current?.clientWidth
                ? canvasRef.current.clientWidth - adjustedWidth
                : 600 - adjustedWidth;
            const yPos = canvasRef.current?.clientHeight
                ? canvasRef.current.clientHeight - adjustedHeight
                : 600 - adjustedHeight;

            setTransform(Math.round(xPos / 2), Math.round(yPos / 2), 1);
        };
    }, [imgSrc]);

    useTransformEffect(({state}) => {
        setImgScale(state.scale);

        if (state.scale >= 8) {
            setDisableZoomIn(true);
        } else {
            setDisableZoomIn(false);
        }

        if (state.scale <= 1) {
            setDisableZoomOut(true);
        } else {
            setDisableZoomOut(false);
        }
    });

    return <Fragment>{children}</Fragment>;
};

/**
 *
 * @param props The zoom image props
 * @returns A canvas allowing the user to zoom in on the image
 */
export function ZoomCanvas({
    autoreportUrl,
    rasterUrl,
    evalData,
    selectedCanvasItemId,
    setSelectedCanvasItemId,
}: {
    autoreportUrl: string | null | undefined;
    rasterUrl: string;
    evalData: ReportType;
    selectedCanvasItemId: string | null;
    setSelectedCanvasItemId: React.Dispatch<React.SetStateAction<string | null>>;
}): React.ReactElement {
    const theme: Theme = useTheme() as Theme;

    const regionFilters = React.useMemo(() => {
        return Array.from(
            new Set(evalData.regions.filter(region => region.shape_type !== "point").map(region => region.label)),
        );
    }, [evalData]);
    const manualTagFilters = React.useMemo(() => {
        return Array.from(
            new Set(evalData.regions.filter(region => region.shape_type === "point").map(region => region.label)),
        );
    }, [evalData]);

    const hasAggregatedRegions = !!evalData.aggregated_regions?.length;

    const [imgSrc, setImgSrc] = useState(rasterUrl);
    const [imgWidth, setImgWidth] = useState<number | string>(0);
    const [imgHeight, setImgHeight] = useState<number | string>(0);
    const [imgRatio, setImgRatio] = useState<number>(1);
    const [imgScale, setImgScale] = useState<number>(1);
    const [disableZoomIn, setDisableZoomIn] = useState<boolean>(false);
    const [disableZoomOut, setDisableZoomOut] = useState<boolean>(true);
    const [areViolationsVisible, setViolationsVisible] = useState(["cross_slope", "run_slope", "clear_width"]);
    const [arePolygonsVisible, setPolygonsVisible] = useState(regionFilters);
    const [areDisplacementsVisible, setDisplacementsVisible] = useState(manualTagFilters);
    const [showAggregatedRegions, setShowAggregatedRegions] = useState<boolean>(false);

    const zoomImgRef = useRef<HTMLImageElement>(null);
    const canvasRef = useRef<HTMLDivElement>(null);
    const transformComponentRef = useRef(null);

    const PAPER_WIDTH = 600;
    const PAPER_HEIGHT = 600;

    useEffect(() => {
        setViolationsVisible(["cross_slope", "run_slope", "clear_width"]);
        setPolygonsVisible(regionFilters);
        setDisplacementsVisible(manualTagFilters);
    }, [regionFilters, manualTagFilters]);

    useEffect(() => {
        // change the image source when the feature is changed
        setImgSrc("");
        setImgSrc(rasterUrl);
    }, [rasterUrl]);

    useEffect(() => {
        if (imgSrc) {
            const img = new Image();
            img.src = imgSrc;
            img.onload = () => {
                const IMAGE_WIDTH = img.width;
                const IMAGE_HEIGHT = img.height;

                if (IMAGE_WIDTH >= IMAGE_HEIGHT) {
                    // checking the image ratio
                    setImgWidth(PAPER_WIDTH);
                    setImgHeight("auto");
                    setImgRatio(PAPER_WIDTH / IMAGE_WIDTH);
                } else {
                    setImgWidth("auto");
                    setImgHeight(PAPER_HEIGHT);
                    setImgRatio(PAPER_HEIGHT / IMAGE_HEIGHT);
                }
            };
        }
    }, [imgSrc]);

    return (
        <Box
            ref={canvasRef}
            position="relative"
            width="100%"
            height="100%"
            borderRadius={theme.shape.borderRadius * 3}
            boxShadow={theme.shadows[4]}>
            <TransformWrapper initialScale={1} ref={transformComponentRef} wheel={{step: 0.5}}>
                {utils => (
                    <ZoomContainer
                        {...utils}
                        canvasRef={canvasRef}
                        imgSrc={imgSrc}
                        setImgScale={setImgScale}
                        setDisableZoomIn={setDisableZoomIn}
                        setDisableZoomOut={setDisableZoomOut}>
                        <Stack
                            gap={4}
                            sx={{
                                position: "absolute",
                                mt: 2,
                                ml: 2,
                            }}>
                            <ZoomControls {...utils} disableZoomIn={disableZoomIn} disableZoomOut={disableZoomOut} />
                            <DownloadButtons pdfLink={autoreportUrl} scanLink={rasterUrl} />
                        </Stack>
                        <Paper
                            sx={{position: "absolute", right: 0, mt: 2, mr: 12, zIndex: 10, borderRadius: 50, px: 2}}>
                            {hasAggregatedRegions && (
                                <FormControlLabel
                                    sx={{mr: 0}}
                                    control={
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlank color="primary" sx={{opacity: 0.5}} />}
                                            checked={showAggregatedRegions}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                setShowAggregatedRegions(event.target.checked)
                                            }
                                            color="info"
                                        />
                                    }
                                    label="Aggregate Regions"
                                />
                            )}
                        </Paper>
                        <Stack
                            gap={2}
                            alignItems="flex-end"
                            sx={{position: "absolute", right: 0, mt: 2, mr: 2, zIndex: 10}}>
                            <FilterMenu
                                {...{
                                    regionFilters,
                                    manualTagFilters,
                                    areViolationsVisible,
                                    setViolationsVisible,
                                    arePolygonsVisible,
                                    setPolygonsVisible,
                                    areDisplacementsVisible,
                                    setDisplacementsVisible,
                                }}
                            />
                            <TableLegend {...{evalData, showAggregatedRegions}} />
                        </Stack>
                        <TransformComponent
                            wrapperStyle={{
                                position: "relative",
                                width: "100%",
                                height: "100%",
                                borderRadius: theme?.shape.borderRadius * 3,
                                zIndex: 1,
                                backgroundColor: "black",
                            }}
                            contentStyle={{cursor: "grab", position: "relative", zIndex: 2}}>
                            <Box width="100%" height="100%" position="absolute">
                                {showAggregatedRegions && evalData.aggregated_regions ? (
                                    <AggregatedRegionOverlay
                                        aggregatedRegions={evalData.aggregated_regions}
                                        {...{imgRatio, selectedCanvasItemId, setSelectedCanvasItemId}}
                                    />
                                ) : (
                                    <>
                                        <RegionOverlay
                                            {...{
                                                evalData,
                                                arePolygonsVisible,
                                                imgRatio,
                                                selectedCanvasItemId,
                                                setSelectedCanvasItemId,
                                                showAggregatedRegions,
                                            }}
                                        />
                                        <WarningOverlay
                                            {...{
                                                evalData,
                                                imgRatio,
                                                imgScale,
                                                areViolationsVisible,
                                                selectedCanvasItemId,
                                                setSelectedCanvasItemId,
                                                showAggregatedRegions,
                                            }}
                                        />
                                    </>
                                )}
                                <VerticalDisplacementOverlay
                                    pointData={evalData.regions.filter(
                                        (region: EvalRegion) => region.shape_type === "point",
                                    )}
                                    {...{
                                        areDisplacementsVisible,
                                        imgRatio,
                                        imgScale,
                                        selectedCanvasItemId,
                                        setSelectedCanvasItemId,
                                    }}
                                />
                            </Box>
                            <img ref={zoomImgRef} src={imgSrc} width={imgWidth} height={imgHeight} />
                        </TransformComponent>
                    </ZoomContainer>
                )}
            </TransformWrapper>
        </Box>
    );
}
