import React from "react";
import type {EvalRegion, ReportType} from "react_ct/types";
import {HtmlTooltip} from "./HtmlTooltip";
import Stack from "@mui/material/Stack";
import {InfoOutlined, PriorityHigh} from "@mui/icons-material";
import {colors} from "react_ct/theme";
import Typography from "@mui/material/Typography";
import {rgbToHex} from "@mui/material/styles";
import {Box, Divider} from "@mui/material";
import {capitalizeFirstLetterOfEachWord, toProperCase} from "helpers/utils";
import {measurementUnits, regionColors} from "../../constants";

function PolygonShape({
    index,
    polygon,
    arePolygonsVisible,
    imgRatio,
    selectedCanvasItemId,
    setSelectedCanvasItemId,
}: {
    index: number;
    polygon: EvalRegion;
    arePolygonsVisible: string[];
    imgRatio: number;
    selectedCanvasItemId: string | null;
    setSelectedCanvasItemId: React.Dispatch<React.SetStateAction<string | null>>;
}): React.ReactElement {
    const [openTooltip, setOpenTooltip] = React.useState<boolean>(false);

    const itemId = `polygon-${index}`;

    const onClick = (): void => {
        if (selectedCanvasItemId !== itemId) setSelectedCanvasItemId(itemId);
        else setSelectedCanvasItemId(null);
    };

    const onOpen = (): void => {
        setOpenTooltip(true);
    };

    const onClose = (): void => {
        setOpenTooltip(false);
    };

    React.useEffect(() => {
        if (openTooltip && selectedCanvasItemId !== itemId) onClose();
    }, [selectedCanvasItemId]);

    const panelHasProwagException = !!polygon.exceptions;

    console.assert(!polygon.exceptions, "region has exception");

    return (
        <HtmlTooltip
            followCursor
            id={itemId}
            placement="top"
            open={openTooltip}
            {...{onClick, onOpen, onClose}}
            disableHoverListener={selectedCanvasItemId === itemId}
            title={
                <>
                    <Stack direction="row" alignItems="center">
                        {polygon.label !== "Panel" && polygon.label !== "Flare" && (
                            <PriorityHigh
                                sx={{
                                    color: regionColors[polygon.label] ?? colors.lightGray,
                                }}
                            />
                        )}
                        <Typography fontWeight="bold" mb={1}>
                            {polygon.label === "Gutter_Steps" ? "Steps" : polygon.label}
                        </Typography>
                    </Stack>
                    <Stack>
                        {polygon.measurements && (
                            <Stack>
                                {Object.entries(polygon.measurements)
                                    .filter(([, val]) => val.value > -1)
                                    .map(([key, val]) => {
                                        return (
                                            <Stack key={key} direction="row" gap={1}>
                                                <Typography>{capitalizeFirstLetterOfEachWord(key)}:</Typography>
                                                <Typography>
                                                    {val.value.toFixed(1)}
                                                    {measurementUnits[val.unit as keyof typeof measurementUnits]}
                                                </Typography>
                                            </Stack>
                                        );
                                    })}
                            </Stack>
                        )}
                        {panelHasProwagException && (
                            <Stack gap={1.5} mt={1} boxSizing="border-box">
                                <Divider />
                                {polygon.exceptions?.perpendicular_ramp_run_slope_exception && (
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        <InfoOutlined color="success" />{" "}
                                        <Typography fontSize="0.8rem" lineHeight="0.8rem">
                                            Perpendicular ramp run slope exception
                                        </Typography>
                                    </Stack>
                                )}
                                {polygon.exceptions?.uncontrolled_ramp_cross_slope_exception && (
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        <InfoOutlined color="success" />
                                        <Typography fontSize="0.8rem" lineHeight="0.8rem">
                                            Uncontrolled ramp cross slope exception
                                        </Typography>
                                    </Stack>
                                )}
                                {polygon.exceptions?.traffic_signal_ramp_cross_slope_exception && (
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        <InfoOutlined color="success" />
                                        <Typography fontSize="0.8rem" lineHeight="0.8rem">
                                            Traffic signal ramp cross slope exception
                                        </Typography>
                                    </Stack>
                                )}
                            </Stack>
                        )}
                    </Stack>
                </>
            }
            sx={{
                display: !arePolygonsVisible.includes(polygon.label) ? "none" : "block",
            }}>
            <path
                onMouseEnter={event => {
                    const color = rgbToHex((event.target as SVGPathElement).style.fill);
                    (event.target as SVGPathElement).style.fill = color.substring(0, color.length - 2) + "66";
                }}
                onMouseLeave={event => {
                    const color = rgbToHex((event.target as SVGPathElement).style.fill);
                    (event.target as SVGPathElement).style.fill =
                        color.substring(0, color.length - 2) + (polygon.label !== "Panel" ? "33" : "00");
                }}
                stroke={regionColors[polygon.label] ?? colors.lightGray}
                strokeWidth={`${
                    polygon.label !== "Panel" ? 4 * imgRatio : panelHasProwagException ? 8 * imgRatio : 2 * imgRatio
                }px`}
                d={[
                    `M ${polygon.points[0][0] * imgRatio} ${polygon.points[0][1] * imgRatio}`,
                    ...polygon.points.map(point => `L${point[0] * imgRatio} ${point[1] * imgRatio}`),
                    `L ${polygon.points[0][0] * imgRatio} ${polygon.points[0][1] * imgRatio}`,
                ].join(" ")}
                style={{
                    position: "relative",
                    display: !arePolygonsVisible.includes(polygon.label) ? "none" : "block",
                    zIndex: polygon.label === "Panel" ? 10 : 9,
                    cursor: "pointer",
                    fill: (regionColors[polygon.label] ?? colors.lightGray) + "33",
                    transition: "fill 0.1s",
                }}
            />
        </HtmlTooltip>
    );
}

function LinestripShape({
    lineRegion,
    imgRatio,
    index,
    selectedCanvasItemId,
    setSelectedCanvasItemId,
}: {
    lineRegion: EvalRegion;
    imgRatio: number;
    index: number;
    selectedCanvasItemId: string | null;
    setSelectedCanvasItemId: React.Dispatch<React.SetStateAction<string | null>>;
}): React.ReactElement {
    const [openTooltip, setOpenTooltip] = React.useState(false);
    const itemId = `linestrip-${index}`;

    const onClick = (): void => {
        if (selectedCanvasItemId !== itemId) setSelectedCanvasItemId(itemId);
        else setSelectedCanvasItemId(null);
    };

    const onOpen = (): void => {
        setOpenTooltip(true);
    };

    const onClose = (): void => {
        setOpenTooltip(false);
    };

    return (
        <HtmlTooltip
            id={itemId}
            open={openTooltip}
            disableHoverListener={selectedCanvasItemId === itemId}
            followCursor
            title={
                <Box>
                    <Stack>
                        <Typography fontWeight="bold">{lineRegion.label}</Typography>
                        {lineRegion.measurements && (
                            <Stack>
                                {Object.entries(lineRegion.measurements)
                                    .filter(([, val]) => val.value > -1)
                                    .map(([key, val]) => {
                                        return (
                                            <Typography key={key}>
                                                {toProperCase(key)}: {val.value}
                                            </Typography>
                                        );
                                    })}
                            </Stack>
                        )}
                    </Stack>
                </Box>
            }
            {...{onClick, onOpen, onClose}}>
            <polyline
                points={lineRegion.points.map(point => point.map(num => num * imgRatio).join(",")).join(" ")}
                strokeWidth={4 * imgRatio}
                stroke={colors.lightBlue}
                fill="none"
            />
        </HtmlTooltip>
    );
}

export default function RegionOverlay({
    evalData,
    arePolygonsVisible,
    imgRatio,
    selectedCanvasItemId,
    setSelectedCanvasItemId,
    showAggregatedRegions,
}: {
    evalData: ReportType;
    arePolygonsVisible: string[];
    imgRatio: number;
    selectedCanvasItemId: string | null;
    setSelectedCanvasItemId: React.Dispatch<React.SetStateAction<string | null>>;
    showAggregatedRegions: boolean;
}): React.ReactElement {
    const regionPolygons: EvalRegion[] = evalData.regions.filter(
        (region: EvalRegion) => region.shape_type === "polygon",
    );
    const sortedPolygons = regionPolygons.sort((a, b) => {
        if (a.label === "Panel") {
            return -1;
        } else if (b.label === "Panel") {
            return 1;
        } else {
            return 0;
        }
    });
    const lineRegions: EvalRegion[] = evalData.regions.filter(
        (region: EvalRegion) => region.shape_type === "linestrip",
    );

    return (
        <svg width="100%" height="100%" style={{position: "absolute"}}>
            {sortedPolygons
                .filter(region => region.label !== "DoNotMeasure")
                .map((polygon: EvalRegion, index) => {
                    return (
                        <PolygonShape
                            key={index}
                            {...{
                                index,
                                evalData,
                                polygon,
                                arePolygonsVisible,
                                imgRatio,
                                selectedCanvasItemId,
                                setSelectedCanvasItemId,
                                showAggregatedRegions,
                            }}
                        />
                    );
                })}
            {lineRegions.map((line: EvalRegion, index) => {
                return (
                    <LinestripShape
                        key={index}
                        lineRegion={line}
                        {...{imgRatio, index, selectedCanvasItemId, setSelectedCanvasItemId}}
                    />
                );
            })}
        </svg>
    );
}
